<template>
  <div class="button help-item" @click="showAnswer = !showAnswer">
    <div class="content">
      <div ref="title" class="title">
        <div class="question">{{ data.question }}</div>
        <div
          ref="arrow"
          class="arrow"
          :style="{ backgroundImage: helpImageUrl }"
        ></div>
      </div>
      <div ref="listAnswer" class="list-answer" v-if="showAnswer">
        <div class="answer" v-for="answer in data.answer" :key="answer">
          {{ answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { required: true },
    helpImageUrl: { required: true },
  },
  data() {
    return {
      showAnswer: false,
      scale: undefined,
    };
  },
  methods: {
    handleResponsive() {
      const scale = this.$refs.title.clientWidth * 0.0016;

      if (this.showAnswer) {
        const answerWidth = this.$refs.listAnswer.clientWidth;
        this.$refs.listAnswer.style.borderWidth = `${answerWidth * 0.009}px`;

        this.$refs.arrow.style.transform = `scaleX(${scale}) scaleY(-${scale})`;
      } else {
        this.$refs.arrow.style.transform = `scaleX(${scale}) scaleY(${scale})`;
      }
    },
  },
  updated() {
    this.handleResponsive();
  },
  mounted() {
    this.handleResponsive();
    window.addEventListener('resize', this.handleResponsive);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResponsive);
  },
};
</script>

<style scoped>
.help-item {
  color: #95693f;
  margin-bottom: 3.4%;
}

.content {
  border-radius: 0.8em;
  background-color: #faf8ee;
  border: 0.2em solid #dfcfa5;
  box-shadow: 0 0.2em #dfcfa5;
}

.title {
  font-size: 0.9em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4% 5%;
}

.question {
  width: 87%;
}

.arrow {
  width: 40px;
  height: 26px;
  background-position: -333px -150px;
  transform-origin: right 50%;
  -ms-transform-origin: right 50%; /* IE 9 */
}

.list-answer {
  padding: 4% 6%;
  border-top: 0 dashed #dfcfa5;
}

.answer {
  color: #876e5e;
  line-height: 1.4;
  font-size: 0.8em;
}

.answer {
  line-height: 1.4;
  font-size: 0.8em;
  color: #876e5e;
}

.answer:not(:first-child) {
  margin-top: 2%;
}

.close-arrow {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
</style>