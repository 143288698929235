<template>
  <div id="help-menu" :style="{ backgroundImage: tileImageUrl }">
    <HelpHeader :helpImageUrl="helpImageUrl"></HelpHeader>
    <div class="help-content">
      <HelpItem
        :helpImageUrl="helpImageUrl"
        v-for="item in helpMenuData"
        :key="item.question"
        :data="item"
      ></HelpItem>
      <FeedbackBox />
      <span class="help-center">
        問題解決できない場合は<br/>
        <a href="javascript:void(0)" @click="helpCenterClicked">ヘルプセンター</a>
        にお問い合わせください
      </span>
    </div>
  </div>
</template>

<script>
import HelpHeader from './HelpHeader.vue';
import HelpItem from './HelpItem.vue';
import FeedbackBox from './FeedbackBox.vue';
import { HelpItemList } from '../../../containers/help/HelpMenu';
import { helpCenterClicked } from '../../../utils/Utils';

export default {
  components: {
    HelpHeader,
    HelpItem,
    FeedbackBox,
  },
  data() {
    return {
      helpImageUrl: 'url(images/help/img_help.png)',
      tileImageUrl: 'url(images/game/common/repeat/bg_repeatGuideHelp.png)',
      helpMenuData: HelpItemList,
    };
  },
  methods: {
    helpCenterClicked,
  },
};
</script>

<style scoped>
#help-menu {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  background-repeat: repeat-y;
  background-size: contain;
  z-index: 1;
}

.help-content {
  height: 100%;
  padding: 6%;
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.help-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.help-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.help-center {
  display: block;
  margin-top: 7.067%;
  font-size: 0.76em;
  font-weight: 600;
  text-align: center;
  color: #96693b;
  font-family: roundedMplus;
}

.help-center > a {
  color: #58bba7;
}
</style>