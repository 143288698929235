<template>
  <div ref="container" class="container">
    <div
      ref="allTab"
      class="button all-tab"
      :style="{ 'background-image': noticeImageUrl }"
      @click="changeTab(NotiTabType.All)"
    ></div>
    <div
      ref="updateTab"
      class="button update-tab"
      :style="{ 'background-image': noticeImageUrl }"
      @click="changeTab(NotiTabType.Update)"
    ></div>
    <div
      ref="notiTab"
      class="button noti-tab"
      :style="{ 'background-image': noticeImageUrl }"
      @click="changeTab(NotiTabType.Other)"
    ></div>
  </div>
</template>

<script>
import { NotiTabType } from '../../../containers/notification/NotiTabs';

export default {
  props: {
    noticeImageUrl: {
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
  },

  watch: {
    currentTab() {
      this.selectCurrentTab();
    },
  },

  data() {
    return {
      NotiTabType: NotiTabType,
    };
  },

  methods: {
    resetAllTab() {
      this.$refs.allTab.style.backgroundPosition = `0 -279px`;
      this.$refs.allTab.style.bottom = `84%`;
      this.$refs.updateTab.style.backgroundPosition = `-203px -279px`;
      this.$refs.updateTab.style.bottom = `84%`;
      this.$refs.notiTab.style.backgroundPosition = `-405px -279px`;
      this.$refs.notiTab.style.bottom = `84%`;
    },

    selectCurrentTab() {
      this.resetAllTab();

      switch (this.currentTab) {
        case NotiTabType.All:
          this.$refs.allTab.style.backgroundPosition = `-1px -190px`;
          this.$refs.allTab.style.bottom = `83.5%`;
          break;
        case NotiTabType.Update:
          this.$refs.updateTab.style.backgroundPosition = `-203px -190px`;
          this.$refs.updateTab.style.bottom = `83.5%`;
          break;
        case NotiTabType.Other:
          this.$refs.notiTab.style.backgroundPosition = `-405px -190px`;
          this.$refs.notiTab.style.bottom = `83.5%`;
          break;
      }
    },

    changeTab(targetTab) {
      this.$emit('changeTab', targetTab);
    },

    handleResponsive() {
      // Set scale of title image responsive
      const scale = this.$refs.container.clientWidth * 0.00132;
      this.$refs.allTab.style.transform = `scale(${scale})`;
      this.$refs.updateTab.style.transform = `scale(${scale})`;
      this.$refs.notiTab.style.transform = `scale(${scale})`;
    },
  },

  mounted() {
    this.handleResponsive();
    window.addEventListener('resize', this.handleResponsive);

    this.selectCurrentTab();
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResponsive);
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}

.all-tab {
  width: 200px;
  height: 88px;
  background-position: 0 -279px;
  position: absolute;
  left: 6.5%;
  bottom: 84%;
  transform-origin: left bottom;
  -ms-transform-origin: left bottom; /* IE 9 */
  z-index: 1;
}

.update-tab {
  width: 200px;
  height: 88px;
  background-position: -203px -279px;
  position: absolute;
  left: 37%;
  bottom: 84%;
  transform-origin: left bottom;
  -ms-transform-origin: left bottom; /* IE 9 */
  z-index: 1;
}

.noti-tab {
  width: 200px;
  height: 88px;
  background-position: -405px -279px;
  position: absolute;
  right: 6.5%;
  bottom: 84%;
  transform-origin: right bottom;
  -ms-transform-origin: right bottom; /* IE 9 */
  z-index: 1;
}
</style>