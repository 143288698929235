<template>
  <div ref="notiItem" class="button noti-item" @click="itemClickHandler">
    <div ref="categoryLabel" class="category-label">
      {{ itemData.categoryJaName }}
    </div>
    <div
      ref="arrow"
      class="arrow"
      :style="{
        backgroundImage: 'url(images/information/img_information.png)',
      }"
    ></div>
    <div class="title">{{ itemData.title }}</div>
    <div class="release-date">{{ releaseDate }}</div>
  </div>
</template>

<script>
import { Utils } from '../../../utils/Utils';
import { NotiLabelType } from '../../../constants/GameConst';

export default {
  props: {
    itemData: {
      required: true,
    },
  },

  computed: {
    releaseDate() {
      return Utils.getDateString(this.itemData.startedAt, 'YYYY/MM/DD');
    },
  },

  methods: {
    setCategoryColor() {
      switch (this.itemData.categoryEnName) {
        case NotiLabelType.Normal: {
          this.$refs.categoryLabel.style.background = '#c4c4c4';
          break;
        }
        case NotiLabelType.Event: {
          this.$refs.categoryLabel.style.background = '#ec9090';
          break;
        }
        case NotiLabelType.Maintenance: {
          this.$refs.categoryLabel.style.background = '#ffd253';
          break;
        }
        case NotiLabelType.Release: {
          this.$refs.categoryLabel.style.background = '#9bd996';
          break;
        }
      }
    },

    itemClickHandler() {
      this.$store.commit('setItemDetail', this.itemData);
    },

    handleResponsive() {
      // Set scale of arrow image responsive
      const containerWidth = this.$refs.notiItem.clientWidth;
      const scale = containerWidth * 0.0016;
      this.$refs.arrow.style.transform = `translateY(-50%) scale(${scale})`;

      // Make bottom border responsive
      this.$refs.notiItem.style.borderBottomWidth = `${
        containerWidth * 0.009
      }px`;
    },
  },

  mounted() {
    this.handleResponsive();
    window.addEventListener('resize', this.handleResponsive);

    // Set color for category label\
    this.setCategoryColor();
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResponsive);
  },
};
</script>

<style scoped>
.noti-item {
  color: #865c35;
  height: 21.5%;
  border-bottom: solid #e8dfc7;
  position: relative;
}

.noti-item:last-child {
  margin-bottom: 3%;
}

.arrow {
  position: absolute;
  right: 5%;
  top: 50%;
  width: 23px;
  height: 34px;
  background-position: -619px -289px;
  transform-origin: right center;
}

.category-label {
  color: #fff;
  font-weight: 600;
  position: absolute;
  left: 5%;
  top: 16%;
  font-size: 0.8em;
  text-align: center;
  border-radius: 25px;
  width: 32%;
}

.title {
  font-size: 0.9em;
  color: #8c623a;
  position: absolute;
  left: 5%;
  top: 55%;
  width: 82%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.release-date {
  font-size: 0.9em;
  color: #99866c;
  position: absolute;
  left: 41%;
  top: 16%;
}
</style>
