<template>
  <div ref="container" id="help-header">
    <div
      ref="background"
      class="background"
      :style="{ backgroundImage: commonImageUrl }"
    ></div>
    <div class="buttons">
      <div
        ref="backButton"
        class="button back-button"
        :style="{ backgroundImage: commonImageUrl }"
        @click="backButtonHandler"
      ></div>
      <div
        ref="title"
        class="title"
        :style="{ backgroundImage: helpImageUrl }"
      ></div>
      <div @click="goToGuide" class="button guide-button flex-center">
        遊び方を見る
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    helpImageUrl: { required: true },
  },
  data() {
    return {
      commonImageUrl: 'url(images/game/common/img_commonObject.png)',
    };
  },
  methods: {
    backButtonHandler() {
      this.$store.commit('showComponent', {
        component: 'helpMenu',
        isShow: false,
      });
    },
    goToGuide() {
      this.backButtonHandler();
      this.$store.commit('showComponent', {
        component: 'guideMenu',
        isShow: true,
      });
    },
    handleResponsive() {
      // Set scale of title image responsive
      const containerWidth = this.$refs.container.clientWidth;
      this.$refs.title.style.transform = `scale(${containerWidth * 0.0012})`;

      // Make background responsive
      this.$refs.background.style.backgroundPosition = `0 ${
        -101 * containerWidth * 0.00133
      }px`;
      this.$refs.background.style.height = `${
        111 * containerWidth * 0.00133
      }px`;

      // Make back button responsive
      this.$refs.backButton.style.transform = `scale(${
        containerWidth * 0.0013
      })`;
    },
  },
  mounted() {
    this.handleResponsive();
    window.addEventListener('resize', this.handleResponsive);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResponsive);
  },
};
</script>

<style scoped>
#help-header {
  color: #865c35;
  position: relative;
}

.background {
  background-position: 0px -101px;
  background-size: 100%;
}

.buttons {
  position: absolute;
  padding: 0 3%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.back-button {
  width: 77px;
  height: 83px;
  background-position: -479px 0px;
  transform-origin: left center;
  -ms-transform-origin: left center; /* IE 9 */
}

.title {
  position: absolute;
  left: 13%;
  width: 332px;
  height: 53px;
  margin-left: 3%;
  background-position: 0 -151px;
  transform-origin: 0 center;
  -ms-transform-origin: 0 center; /* IE 9 */
}

.guide-button {
  position: relative;
  top: -5%;
  width: 34%;
  height: 53%;
  color: #825d38;
  background-color: #ebc583;
  margin-left: auto;
  font-size: 0.85em;
  font-weight: 600;
  border-radius: 0.6em;
  -webkit-box-shadow: 0 0.25em 0 0 #d6ab63;
  -moz-box-shadow: 0 0.25em 0 0 #d6ab63;
  box-shadow: 0 0.25em 0 0 #d6ab63;
}
</style>