"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = exports.ColorPalette = void 0;
/** Colors with string type */
var ColorPalette;
(function (ColorPalette) {
    ColorPalette["Background"] = "#87d074";
    ColorPalette["Primary"] = "#58bba7";
    ColorPalette["PrimaryShadow"] = "#59a991";
    ColorPalette["PrimaryBold"] = "#45a38e";
    ColorPalette["PrimaryLabel"] = "#78c9b9";
    ColorPalette["Secondary"] = "#e4faf4";
    ColorPalette["Accent"] = "#f59783";
    ColorPalette["AccentBold"] = "#d68272";
    ColorPalette["Text"] = "#8c623a";
    ColorPalette["CategoryText"] = "#597000";
    ColorPalette["Green"] = "#819e15";
    ColorPalette["GreenBold"] = "#6f8a07";
    ColorPalette["GreenLight"] = "#d6e889";
    ColorPalette["GreenLightSelect"] = "#b1c953";
    ColorPalette["GreenGrass"] = "#5aa94d";
    ColorPalette["GreenGrassDark"] = "#6ec37d";
    ColorPalette["ControlGreen"] = "#14804f";
    ColorPalette["GreenHistoryDay"] = "#78aba0";
    ColorPalette["GreenHistoryDark"] = "#4a8075";
    ColorPalette["GreenCategoryBg"] = "#93ad2b";
    ColorPalette["SeedListTitle"] = "#2f947e";
    ColorPalette["HarvestStatus"] = "#95693f";
    ColorPalette["CapacityText"] = "#347364";
    ColorPalette["CapacityBar"] = "#6ebfae";
    ColorPalette["Brown"] = "#8f6d3a";
    ColorPalette["BrownLight"] = "#e9c381";
    ColorPalette["BrownBold"] = "#7a542e";
    ColorPalette["BrownCat"] = "#ab8644";
    ColorPalette["BrownCatDark"] = "#96773e";
    ColorPalette["BrownDeliveryItem"] = "#ab8435";
    ColorPalette["BrownStatus"] = "#95693f";
    ColorPalette["BrownPostLimited"] = "#b3987d";
    ColorPalette["BrownNotification"] = "#99866c";
    ColorPalette["BrownOcher"] = "#8c6b25";
    ColorPalette["BrownDarkOcher"] = "#c4a056";
    ColorPalette["BrownLightOcher"] = "#e9cb7e";
    ColorPalette["BrownLoginBonus"] = "#83512f";
    ColorPalette["BrownSale"] = "#bd8d60";
    ColorPalette["ItemLockedStroke"] = "#665033";
    ColorPalette["ShopBtnDefault"] = "#ad6c2b";
    ColorPalette["ShopBtnDark"] = "#965b1b";
    ColorPalette["ShopBtnIconDefault"] = "#ebc373";
    ColorPalette["ShopBtnIconDark"] = "#cc9650";
    ColorPalette["ItemPriceColor"] = "#82510c";
    ColorPalette["ItemPriceStroke"] = "#e3c49f";
    ColorPalette["QuantityInStock"] = "#c99f6b";
    ColorPalette["AmountBox"] = "#f7f6e8";
    ColorPalette["CraftBtnDefault"] = "#9a6a39";
    ColorPalette["CraftBtnDark"] = "#855d33";
    ColorPalette["CraftBtnIconDefault"] = "#d7ae67";
    ColorPalette["CraftBtnIconDark"] = "#b3844a";
    ColorPalette["CraftBtnArrow"] = "#9a6a39";
    ColorPalette["CraftSlotBorder"] = "#ba975b";
    ColorPalette["CraftSlotLock"] = "#807868";
    ColorPalette["CraftSlotLockIcon"] = "#7a564e";
    ColorPalette["CraftCategoryTitle"] = "#704f2a";
    ColorPalette["IncreaseLeftSlot"] = "#f7f6e9";
    ColorPalette["CraftSlotEmpty"] = "#826842";
    ColorPalette["CraftPopupStroke"] = "#82673f";
    ColorPalette["CraftItemColonLack"] = "#eb6421";
    ColorPalette["Black"] = "#000000";
    ColorPalette["BlackLight"] = "#787878";
    ColorPalette["Gray"] = "#e0e0e0";
    ColorPalette["GrayBold"] = "#adadad";
    ColorPalette["BackButtonText"] = "#787878";
    ColorPalette["CloseButtonText"] = "#787878";
    ColorPalette["WhiteLight"] = "#fcf3f2";
    ColorPalette["ControlWhite"] = "#f7f6e9";
    ColorPalette["White"] = "#ffffff";
    ColorPalette["DialogBg"] = "#f7f6e9";
    ColorPalette["WhiteBg"] = "#f7f6ea";
    ColorPalette["WhiteAvatarItem"] = "#f7f6e9";
    ColorPalette["WhiteCapacityText"] = "#f7f6e9";
    ColorPalette["ProgressBox"] = "#9e6024";
    ColorPalette["ProgressBar"] = "#cf662b";
    ColorPalette["Quantity"] = "#cea76d";
    ColorPalette["QuantityWareHouse"] = "#c99f6b";
    ColorPalette["LightBlack"] = "#6f6c6b";
    ColorPalette["GreenIcon"] = "#58bba7";
    ColorPalette["GreyInfoBg"] = "#e8dfc7";
    ColorPalette["GreyDarkInfoBg"] = "#dbcaa0";
    ColorPalette["GreyBorder"] = "#d6c598";
    ColorPalette["RedText"] = "#fa897d";
    ColorPalette["RedButton"] = "#f59783";
    ColorPalette["RedDarkButton"] = "#d68272";
    ColorPalette["GreyDisabledBg"] = "#bdbdbd";
    ColorPalette["GreyDisabledStroke"] = "#b0b0b0";
    ColorPalette["GreyDisabledText"] = "#e7e7e7";
    ColorPalette["TurquoiseDivider"] = "#84cfc1";
    ColorPalette["ItemDivideLine"] = "#e5ccad";
    ColorPalette["MissionProgress"] = "#8cd6c9";
    ColorPalette["MissionCategorySelect"] = "#b1f1e5";
    ColorPalette["MissionTitleLine"] = "#ded2b4";
    ColorPalette["RewardReceivedBg"] = "#c8c7c4";
    ColorPalette["TurquoiseLightBg"] = "#88e3d1";
    ColorPalette["GreenStroke"] = "#58a444";
    ColorPalette["LeftMenuButton"] = "#5eccb9";
    ColorPalette["MenuButton"] = "#ede7d5";
    ColorPalette["MenuButtonShadow"] = "#ded5be";
    ColorPalette["MenuButtonBg"] = "#f7f5eb";
    ColorPalette["GrayNotification"] = "#c4c4c4";
    ColorPalette["RedNotification"] = "#ec9090";
    ColorPalette["YellowNotification"] = "#ffd253";
    ColorPalette["GreenNotification"] = "#9bd996";
    ColorPalette["GrayDivideLine"] = "#d6c598";
    ColorPalette["DeliveryChangeText"] = "#787878";
    ColorPalette["GrayCapacityText"] = "#787878";
    ColorPalette["ItemNotChangeText"] = "#9c8978";
    ColorPalette["BrownCategoryTitle"] = "#a1672a";
    ColorPalette["GoHelpTitle"] = "#825d38";
    ColorPalette["GuideButtonTitle"] = "#95693f";
    ColorPalette["GuideButtonBase"] = "#faf8ee";
    ColorPalette["GoGuideTitle"] = "#825d38";
    ColorPalette["HelpButtonTitle"] = "#95693f";
    ColorPalette["HelpButtonBase"] = "#faf8ee";
    ColorPalette["BrownHelpAnswer"] = "#876e5e";
    ColorPalette["SeedItemBg"] = "#e8dfc7";
    ColorPalette["StrokeAvatar"] = "#bfa17c";
    ColorPalette["Marzipan"] = "#ebc583";
    ColorPalette["MarzipanDark"] = "#d6ab63";
    ColorPalette["BrownOffset"] = "#dfcfa5";
    ColorPalette["CategoryNameBgShop"] = "#bf8341";
    ColorPalette["CategoryNameTextShop"] = "#7a491a";
    ColorPalette["StrokeLoginBonus"] = "#fcfbe8";
    ColorPalette["BlueJewel"] = "#48c3e8";
    ColorPalette["BalanceBoxBg"] = "#f0dec0";
    ColorPalette["WcoinBalance"] = "#e4f6fa";
    ColorPalette["WcoinPrice"] = "#f7f0db";
    ColorPalette["JewelBalance"] = "#faf6e4";
    ColorPalette["TextJewelLimit"] = "#fa7062";
    ColorPalette["BgTextStroke"] = "#ede2ca";
    ColorPalette["JewelUsageHistoryLabel"] = "#b59267";
    ColorPalette["JewelUsageHistoryBtnBg"] = "#faf4e1";
    ColorPalette["JewelUsageHistoryBtnStroke"] = "#d4bf96";
    ColorPalette["SpecifiedCommercialTransaction"] = "#a3764b";
    ColorPalette["YellowColon"] = "#f5a00c";
    ColorPalette["HistoryLightBrown"] = "#99866c";
    ColorPalette["JewelAttention"] = "#008ab8";
    ColorPalette["PopupStroke"] = "#14804f";
    ColorPalette["BackpackOverlay"] = "#3d3326";
    ColorPalette["ToolItemOverlay"] = "#403828";
    ColorPalette["ConfettiBlue"] = "#68d4be";
    ColorPalette["ConfettiRed"] = "#f59783";
    ColorPalette["ConfettiYellow"] = "#f2cc4e";
    ColorPalette["ConfettiLoginBonus"] = "#f9ec73";
    ColorPalette["MagnoliaCream"] = "#f0dfb4";
    ColorPalette["BrownCoffee"] = "#b58250";
    ColorPalette["BrownDarkCoffee"] = "#946748";
    ColorPalette["AlbumCategoryButton"] = "#8c5130";
    ColorPalette["AlbumCategoryButtonActive"] = "#784423";
    ColorPalette["AlbumCategoryDivider"] = "#ad6c42";
    ColorPalette["AlbumCategoryIcon"] = "#cc9370";
    ColorPalette["AlbumCategoryIconActive"] = "#9e6c4c";
    ColorPalette["AlbumFilterBackground"] = "#99613f";
    ColorPalette["AlbumFilterText"] = "#5a3309";
    ColorPalette["GachaLineUpText"] = "#bd8d60";
    ColorPalette["GachaLineUpBorder"] = "#e8dfc7";
    ColorPalette["GachaResultTitle"] = "#439432";
    ColorPalette["GachaLineUpTitleBand"] = "#e0bb82";
    ColorPalette["GachaLineUpTitleBandShadow"] = "#cca870";
    ColorPalette["GachaLineUpBtnShadow"] = "#d1baa3";
    ColorPalette["SelectingBorder"] = "#e3d3aa";
    ColorPalette["SelectingBase"] = "#f7f7e9";
    ColorPalette["GachaBottomBorder"] = "#dbcda9";
    ColorPalette["FeedbackBoxBackground"] = "#eddcab";
    ColorPalette["EmptyAmount"] = "#f2824b";
    ColorPalette["UserNameText"] = "#eddaaf";
    ColorPalette["FeedProgressBackground"] = "#755535";
    ColorPalette["FeedProgressBar"] = "#61a897";
    ColorPalette["FeedProgressNameOutline"] = "#7a532e";
    ColorPalette["FeedProgressAmount"] = "#b58d62";
    ColorPalette["DecideBtnBg"] = "#f59783";
    ColorPalette["DecideBtnShadow"] = "#d68272";
    ColorPalette["PrimaryBtnBg"] = "#58bba7";
    ColorPalette["PrimaryBtnText"] = "#e4faf4";
    ColorPalette["PrimaryBtnShadow"] = "#45a38e";
    ColorPalette["saveTimeQuantityBg"] = "#d9be8f";
    ColorPalette["CancelBtnText"] = "#787878";
    ColorPalette["CancelBtnBg"] = "#e0e0e0";
    ColorPalette["CancelBtnShadow"] = "#adadad";
    ColorPalette["DecideBtnText"] = "#fcf3f2";
    ColorPalette["saveTimeBold"] = "#e0d4b6";
    ColorPalette["SpeedUpItemBorder"] = "#e0d4b6";
    ColorPalette["SpeedUpReducedOutline"] = "#2aa89b";
    ColorPalette["BorderBottomWareHouse"] = "#c9a171";
    ColorPalette["BorderBottomKitchen"] = "#bdae8b";
    ColorPalette["BorderBottomShopSell"] = "#bb8e64";
    ColorPalette["BorderBottomGacha"] = "#dbcda9";
    ColorPalette["BorderArrangePanel"] = "#84cfc1";
    ColorPalette["DivideCategoryWarehouse"] = "#819925";
    ColorPalette["DivideCategoryShop"] = "#ad7a44";
    ColorPalette["DivideCategoryKitchen"] = "#ba934a";
    ColorPalette["DivideCategoryCraftShop"] = "#9e7446";
    ColorPalette["DeliveryMaterialBoardTitle"] = "#fcfbef";
    ColorPalette["DeliveryMaterialBoardTitleBorder"] = "#917336";
    ColorPalette["HalloweenRankingTitle"] = "#b0773e";
    ColorPalette["EventRankItemBorder"] = "#ede7d8";
    ColorPalette["EventRankItemActiveBackground"] = "#faf4e6";
})(ColorPalette = exports.ColorPalette || (exports.ColorPalette = {}));
/** Colors with number type */
var Colors;
(function (Colors) {
    Colors[Colors["Background"] = 8900724] = "Background";
    Colors[Colors["Primary"] = 5815207] = "Primary";
    Colors[Colors["PrimaryShadow"] = 5876113] = "PrimaryShadow";
    Colors[Colors["PrimaryBold"] = 4563854] = "PrimaryBold";
    Colors[Colors["PrimaryLabel"] = 7915961] = "PrimaryLabel";
    Colors[Colors["Secondary"] = 15006452] = "Secondary";
    Colors[Colors["Accent"] = 16095107] = "Accent";
    Colors[Colors["AccentBold"] = 14058098] = "AccentBold";
    Colors[Colors["Text"] = 9200186] = "Text";
    Colors[Colors["CategoryText"] = 5861376] = "CategoryText";
    Colors[Colors["saveTimeQuantityBg"] = 14270095] = "saveTimeQuantityBg";
    Colors[Colors["Green"] = 8494613] = "Green";
    Colors[Colors["GreenBold"] = 7309831] = "GreenBold";
    Colors[Colors["GreenLight"] = 14084233] = "GreenLight";
    Colors[Colors["GreenLightSelect"] = 11651411] = "GreenLightSelect";
    Colors[Colors["GreenGrass"] = 5941581] = "GreenGrass";
    Colors[Colors["GreenGrassDark"] = 7259005] = "GreenGrassDark";
    Colors[Colors["ControlGreen"] = 1343567] = "ControlGreen";
    Colors[Colors["GreenHistoryDark"] = 4882549] = "GreenHistoryDark";
    Colors[Colors["GreenHistoryDay"] = 7908256] = "GreenHistoryDay";
    Colors[Colors["GreenCategoryBg"] = 9678123] = "GreenCategoryBg";
    Colors[Colors["SeedListTitle"] = 3118206] = "SeedListTitle";
    Colors[Colors["HarvestStatus"] = 9791807] = "HarvestStatus";
    Colors[Colors["CapacityText"] = 3437412] = "CapacityText";
    Colors[Colors["CapacityBar"] = 7258030] = "CapacityBar";
    Colors[Colors["Brown"] = 9399610] = "Brown";
    Colors[Colors["BrownLight"] = 15319937] = "BrownLight";
    Colors[Colors["BrownBold"] = 8016942] = "BrownBold";
    Colors[Colors["BrownCat"] = 11241028] = "BrownCat";
    Colors[Colors["BrownCatDark"] = 9860926] = "BrownCatDark";
    Colors[Colors["BrownDeliveryItem"] = 11240501] = "BrownDeliveryItem";
    Colors[Colors["BrownStatus"] = 9791807] = "BrownStatus";
    Colors[Colors["BrownPostLimited"] = 11769981] = "BrownPostLimited";
    Colors[Colors["BrownNotification"] = 10061420] = "BrownNotification";
    Colors[Colors["BrownOcher"] = 9202469] = "BrownOcher";
    Colors[Colors["BrownDarkOcher"] = 12886102] = "BrownDarkOcher";
    Colors[Colors["BrownLightOcher"] = 15321982] = "BrownLightOcher";
    Colors[Colors["BrownLoginBonus"] = 8605999] = "BrownLoginBonus";
    Colors[Colors["BrownSale"] = 12422496] = "BrownSale";
    Colors[Colors["ItemLockedStroke"] = 6705203] = "ItemLockedStroke";
    Colors[Colors["ShopBtnDefault"] = 11365419] = "ShopBtnDefault";
    Colors[Colors["ShopBtnDark"] = 9853723] = "ShopBtnDark";
    Colors[Colors["ShopBtnIconDefault"] = 15450995] = "ShopBtnIconDefault";
    Colors[Colors["ShopBtnIconDark"] = 13407824] = "ShopBtnIconDark";
    Colors[Colors["ItemPriceColor"] = 8540428] = "ItemPriceColor";
    Colors[Colors["ItemPriceStroke"] = 14927007] = "ItemPriceStroke";
    Colors[Colors["QuantityInStock"] = 13213547] = "QuantityInStock";
    Colors[Colors["AmountBox"] = 16250600] = "AmountBox";
    Colors[Colors["CraftBtnDefault"] = 10119737] = "CraftBtnDefault";
    Colors[Colors["CraftBtnDark"] = 8740147] = "CraftBtnDark";
    Colors[Colors["CraftBtnIconDefault"] = 14134887] = "CraftBtnIconDefault";
    Colors[Colors["CraftBtnIconDark"] = 11764810] = "CraftBtnIconDark";
    Colors[Colors["CraftBtnArrow"] = 10119737] = "CraftBtnArrow";
    Colors[Colors["CraftSlotBorder"] = 12228443] = "CraftSlotBorder";
    Colors[Colors["CraftSlotLock"] = 8419432] = "CraftSlotLock";
    Colors[Colors["CraftSlotLockIcon"] = 8017486] = "CraftSlotLockIcon";
    Colors[Colors["CraftCategoryTitle"] = 7360298] = "CraftCategoryTitle";
    Colors[Colors["IncreaseLeftSlot"] = 16250601] = "IncreaseLeftSlot";
    Colors[Colors["CraftSlotEmpty"] = 8546370] = "CraftSlotEmpty";
    Colors[Colors["CraftPopupStroke"] = 8546111] = "CraftPopupStroke";
    Colors[Colors["Black"] = 0] = "Black";
    Colors[Colors["BlackLight"] = 7895160] = "BlackLight";
    Colors[Colors["Gray"] = 14737632] = "Gray";
    Colors[Colors["GrayBold"] = 11382189] = "GrayBold";
    Colors[Colors["BackButtonText"] = 7895160] = "BackButtonText";
    Colors[Colors["CloseButtonText"] = 7895160] = "CloseButtonText";
    Colors[Colors["WhiteLight"] = 16577522] = "WhiteLight";
    Colors[Colors["ControlWhite"] = 16250601] = "ControlWhite";
    Colors[Colors["White"] = 16777215] = "White";
    Colors[Colors["DialogBg"] = 16250601] = "DialogBg";
    Colors[Colors["WhiteBg"] = 16250602] = "WhiteBg";
    Colors[Colors["WhiteAvatarItem"] = 16250601] = "WhiteAvatarItem";
    Colors[Colors["WhiteCapacityText"] = 16250601] = "WhiteCapacityText";
    Colors[Colors["ProgressBox"] = 10379300] = "ProgressBox";
    Colors[Colors["ProgressBar"] = 13592107] = "ProgressBar";
    Colors[Colors["Quantity"] = 13543277] = "Quantity";
    Colors[Colors["LightBlack"] = 7302251] = "LightBlack";
    Colors[Colors["GreenIcon"] = 5815207] = "GreenIcon";
    Colors[Colors["GreyInfoBg"] = 15261639] = "GreyInfoBg";
    Colors[Colors["GreyDarkInfoBg"] = 14404256] = "GreyDarkInfoBg";
    Colors[Colors["GreyBorder"] = 14075288] = "GreyBorder";
    Colors[Colors["RedText"] = 16419197] = "RedText";
    Colors[Colors["RedButton"] = 16095107] = "RedButton";
    Colors[Colors["GreyDisabledBg"] = 12434877] = "GreyDisabledBg";
    Colors[Colors["GreyDisabledStroke"] = 11579568] = "GreyDisabledStroke";
    Colors[Colors["GreyDisabledText"] = 15198183] = "GreyDisabledText";
    Colors[Colors["TurquoiseLightBg"] = 8971217] = "TurquoiseLightBg";
    Colors[Colors["TurquoiseDivider"] = 8703937] = "TurquoiseDivider";
    Colors[Colors["RedDarkButton"] = 14058355] = "RedDarkButton";
    Colors[Colors["ItemDivideLine"] = 15060141] = "ItemDivideLine";
    Colors[Colors["MissionProgress"] = 9230025] = "MissionProgress";
    Colors[Colors["MissionCategorySelect"] = 11661797] = "MissionCategorySelect";
    Colors[Colors["MissionTitleLine"] = 14602932] = "MissionTitleLine";
    Colors[Colors["RewardReceivedBg"] = 13158340] = "RewardReceivedBg";
    Colors[Colors["GreenStroke"] = 5809220] = "GreenStroke";
    Colors[Colors["LeftMenuButton"] = 6212793] = "LeftMenuButton";
    Colors[Colors["MenuButton"] = 15591381] = "MenuButton";
    Colors[Colors["MenuButtonShadow"] = 14603710] = "MenuButtonShadow";
    Colors[Colors["MenuButtonBg"] = 16250347] = "MenuButtonBg";
    Colors[Colors["GrayNotification"] = 12895428] = "GrayNotification";
    Colors[Colors["RedNotification"] = 15503504] = "RedNotification";
    Colors[Colors["YellowNotification"] = 16765523] = "YellowNotification";
    Colors[Colors["GreenNotification"] = 10213782] = "GreenNotification";
    Colors[Colors["GrayDivideLine"] = 14075288] = "GrayDivideLine";
    Colors[Colors["DeliveryChangeText"] = 7895160] = "DeliveryChangeText";
    Colors[Colors["GrayCapacityText"] = 7895160] = "GrayCapacityText";
    Colors[Colors["ItemNotChangeText"] = 10258808] = "ItemNotChangeText";
    Colors[Colors["BrownCategoryTitle"] = 10577706] = "BrownCategoryTitle";
    Colors[Colors["GoHelpTitle"] = 8543544] = "GoHelpTitle";
    Colors[Colors["GuideButtonTitle"] = 9791807] = "GuideButtonTitle";
    Colors[Colors["GuideButtonBase"] = 16447726] = "GuideButtonBase";
    Colors[Colors["GoGuideTitle"] = 8543544] = "GoGuideTitle";
    Colors[Colors["HelpButtonTitle"] = 9791807] = "HelpButtonTitle";
    Colors[Colors["HelpButtonBase"] = 16447726] = "HelpButtonBase";
    Colors[Colors["BrownHelpAnswer"] = 8875614] = "BrownHelpAnswer";
    Colors[Colors["SeedItemBg"] = 15261639] = "SeedItemBg";
    Colors[Colors["StrokeAvatar"] = 12558716] = "StrokeAvatar";
    Colors[Colors["Marzipan"] = 15451523] = "Marzipan";
    Colors[Colors["MarzipanDark"] = 14068579] = "MarzipanDark";
    Colors[Colors["BrownOffset"] = 14667685] = "BrownOffset";
    Colors[Colors["CategoryNameBgShop"] = 12550977] = "CategoryNameBgShop";
    Colors[Colors["CategoryNameTextShop"] = 8014106] = "CategoryNameTextShop";
    Colors[Colors["StrokeLoginBonus"] = 16579560] = "StrokeLoginBonus";
    Colors[Colors["BlueJewel"] = 256426984] = "BlueJewel";
    Colors[Colors["BalanceBoxBg"] = 15785664] = "BalanceBoxBg";
    Colors[Colors["WcoinBalance"] = 266663674] = "WcoinBalance";
    Colors[Colors["WcoinPrice"] = 16249051] = "WcoinPrice";
    Colors[Colors["JewelBalance"] = 16447204] = "JewelBalance";
    Colors[Colors["TextJewelLimit"] = 16412770] = "TextJewelLimit";
    Colors[Colors["JewelUsageHistoryLabel"] = 263557735] = "JewelUsageHistoryLabel";
    Colors[Colors["JewelUsageHistoryBtnBg"] = 16446689] = "JewelUsageHistoryBtnBg";
    Colors[Colors["JewelUsageHistoryBtnStroke"] = 265600918] = "JewelUsageHistoryBtnStroke";
    Colors[Colors["BgTextStroke"] = 267248330] = "BgTextStroke";
    Colors[Colors["SpecifiedCommercialTransaction"] = 262370891] = "SpecifiedCommercialTransaction";
    Colors[Colors["YellowColon"] = 16097292] = "YellowColon";
    Colors[Colors["HistoryLightBrown"] = 10061420] = "HistoryLightBrown";
    Colors[Colors["JewelAttention"] = 251693752] = "JewelAttention";
    Colors[Colors["BackgroundJewel"] = 15457726] = "BackgroundJewel";
    Colors[Colors["PopupStroke"] = 1343567] = "PopupStroke";
    Colors[Colors["BackpackOverlay"] = 4010790] = "BackpackOverlay";
    Colors[Colors["ToolItemOverlay"] = 4208680] = "ToolItemOverlay";
    Colors[Colors["ConfettiBlue"] = 6870206] = "ConfettiBlue";
    Colors[Colors["ConfettiRed"] = 16095107] = "ConfettiRed";
    Colors[Colors["ConfettiYellow"] = 15912014] = "ConfettiYellow";
    Colors[Colors["ConfettiLoginBonus"] = 16378995] = "ConfettiLoginBonus";
    Colors[Colors["MagnoliaCream"] = 15785908] = "MagnoliaCream";
    Colors[Colors["BrownCoffee"] = 11895376] = "BrownCoffee";
    Colors[Colors["BrownDarkCoffee"] = 9725768] = "BrownDarkCoffee";
    Colors[Colors["AlbumCategoryButton"] = 9195824] = "AlbumCategoryButton";
    Colors[Colors["AlbumCategoryButtonActive"] = 7881763] = "AlbumCategoryButtonActive";
    Colors[Colors["AlbumCategoryDivider"] = 11365442] = "AlbumCategoryDivider";
    Colors[Colors["AlbumCategoryIcon"] = 13407088] = "AlbumCategoryIcon";
    Colors[Colors["AlbumCategoryIconActive"] = 10382412] = "AlbumCategoryIconActive";
    Colors[Colors["AlbumFilterBackground"] = 10051903] = "AlbumFilterBackground";
    Colors[Colors["AlbumFilterText"] = 5911305] = "AlbumFilterText";
    Colors[Colors["GachaLineUpText"] = 264080736] = "GachaLineUpText";
    Colors[Colors["GachaLineUpBorder"] = 266919879] = "GachaLineUpBorder";
    Colors[Colors["GachaResultTitle"] = 256087090] = "GachaResultTitle";
    Colors[Colors["GachaLineUpTitleBand"] = 266386306] = "GachaLineUpTitleBand";
    Colors[Colors["GachaLineUpTitleBandShadow"] = 265070704] = "GachaLineUpTitleBandShadow";
    Colors[Colors["GachaLineUpBtnShadow"] = 265403043] = "GachaLineUpBtnShadow";
    Colors[Colors["SelectingBorder"] = 266589098] = "SelectingBorder";
    Colors[Colors["SelectingBase"] = 267909097] = "SelectingBase";
    Colors[Colors["GachaBottomBorder"] = 266063273] = "GachaBottomBorder";
    Colors[Colors["FeedProgressBackground"] = 7689525] = "FeedProgressBackground";
    Colors[Colors["FeedProgressBar"] = 6400151] = "FeedProgressBar";
    Colors[Colors["FeedProgressNameOutline"] = 8016686] = "FeedProgressNameOutline";
    Colors[Colors["FeedbackBoxBackground"] = 15588523] = "FeedbackBoxBackground";
    Colors[Colors["SpeedUpItemBorder"] = 14734518] = "SpeedUpItemBorder";
    Colors[Colors["SpeedUpReducedOutline"] = 2795675] = "SpeedUpReducedOutline";
    Colors[Colors["GrayOverlayToolItem"] = 11711154] = "GrayOverlayToolItem";
    Colors[Colors["BorderBottomWareHouse"] = 13214065] = "BorderBottomWareHouse";
    Colors[Colors["BorderBottomKitchen"] = 12430987] = "BorderBottomKitchen";
    Colors[Colors["BorderBottomShopSell"] = 12291684] = "BorderBottomShopSell";
    Colors[Colors["BorderBottomGacha"] = 14405033] = "BorderBottomGacha";
    Colors[Colors["BorderArrangePanel"] = 8703937] = "BorderArrangePanel";
    Colors[Colors["DivideCategoryWarehouse"] = 8493349] = "DivideCategoryWarehouse";
    Colors[Colors["DivideCategoryShop"] = 11369028] = "DivideCategoryShop";
    Colors[Colors["DivideCategoryKitchen"] = 12227402] = "DivideCategoryKitchen";
    Colors[Colors["DivideCategoryCraftShop"] = 10384454] = "DivideCategoryCraftShop";
    Colors[Colors["DeliveryMaterialBoardTitle"] = 16579567] = "DeliveryMaterialBoardTitle";
    Colors[Colors["DeliveryMaterialBoardTitleBorder"] = 9532214] = "DeliveryMaterialBoardTitleBorder";
    Colors[Colors["HalloweenRankingTitle"] = 11564862] = "HalloweenRankingTitle";
    Colors[Colors["EventRankItemBorder"] = 15591384] = "EventRankItemBorder";
    Colors[Colors["EventRankItemActiveBackground"] = 16446694] = "EventRankItemActiveBackground";
})(Colors = exports.Colors || (exports.Colors = {}));
