<template>
  <div v-if="itemData.body" class="item-detail scrollbar">
    <div class="item-header">
      <div ref="categoryLabel" class="category-label">
        {{ itemData.categoryJaName }}
      </div>
      <div class="release-date">{{ releaseDate }}</div>
      <div class="title">{{ itemData.title }}</div>
    </div>
    <div ref="itemBody" class="item-body" v-html="itemData.body"></div>

    <div @click="closeDetail" class="button back-button flex-center">
      もどる
    </div>
  </div>
</template>

<script>
import { Utils } from '../../../utils/Utils';
import { ColorPalette } from '../../../constants/ColorPalette';
import { NotiLabelType } from '../../../constants/GameConst';

export default {
  computed: {
    itemData() {
      return this.$store.state.currentItemDetail;
    },
    releaseDate() {
      return Utils.getDateString(this.itemData.startedAt, 'YYYY/MM/DD');
    },
  },

  methods: {
    setCategoryColor() {
      switch (this.itemData.categoryEnName) {
        case NotiLabelType.Normal: {
          this.$refs.categoryLabel.style.background =
            ColorPalette.GrayNotification;
          break;
        }
        case NotiLabelType.Event: {
          this.$refs.categoryLabel.style.background =
            ColorPalette.RedNotification;
          break;
        }
        case NotiLabelType.Maintenance: {
          this.$refs.categoryLabel.style.background =
            ColorPalette.YellowNotification;
          break;
        }
        case NotiLabelType.Release: {
          this.$refs.categoryLabel.style.background =
            ColorPalette.GreenNotification;
          break;
        }
      }
    },
    closeDetail() {
      this.$store.commit('setItemDetail', null);
    },
    handleResponsive() {
      // Make border of body responsive
      const bodyWidth = this.$refs.itemBody.clientWidth;
      this.$refs.itemBody.style.borderWidth = `${bodyWidth * 0.009}px`;
    },
    customStyling() {
      // Set color for category label\
      this.setCategoryColor();

      this.handleResponsive();
    },
  },

  created() {
    if (!this.itemData.body) {
      this.$store.dispatch('getNoticeBody', this.itemData);
    }
  },

  updated() {
    this.customStyling();
  },

  mounted() {
    if (this.itemData.body) {
      this.customStyling();
    }

    window.addEventListener('resize', this.handleResponsive);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResponsive);
  },
};
</script>

<style scoped>
.item-detail {
  color: #865c35;
  height: 100%;
  overflow-y: scroll;
}

.item-header {
  padding: 3.3% 5%;
  position: relative;
}

.category-label {
  display: inline-block;
  color: #fff;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  border-radius: 25px;
  width: 35.5%;
}

.title {
  margin-top: 2.2%;
  font-size: 0.9em;
  color: #8c623a;
}

.release-date {
  display: inline-block;
  margin-left: 3%;
  font-size: 0.9em;
  color: #99866c;
}

.item-body {
  font-size: 0.8em;
  color: #8c623a;
  padding: 4% 5%;
  border-top: 5px dotted #e5ccad;
  border-bottom: 5px dotted #e5ccad;
  font-family: 'roundedMplus', sans-serif;
}

.back-button {
  width: 80%;
  height: 11%;
  background-color: #e0e0e0;
  border-radius: 0.7em;
  -webkit-box-shadow: 0 0.25em 0 0 #adadad;
  -moz-box-shadow: 0 0.25em 0 0 #adadad;
  box-shadow: 0 0.25em 0 0#adadad;
  color: #787878;
  font-size: 1.25em;
  font-weight: 900;
  margin: 8% auto 15% auto;
}
</style>
