"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopBuyCategoryConfig = exports.ShopSellCategoryConfig = void 0;
const GameConst_1 = require("../../constants/GameConst");
const TextureKeys_1 = require("../../constants/TextureKeys");
/**
 * Shop Sell Category config
 */
exports.ShopSellCategoryConfig = [
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'all',
        index: 0,
        isSelected: true,
        displayName: 'すべて',
        category: GameConst_1.ItemCategory.All,
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'growth',
        index: 1,
        isSelected: false,
        displayName: '育成',
        category: GameConst_1.ItemCategory.Growth,
        subMenu: [
            {
                texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
                frame: 'all',
                index: 0,
                isSelected: true,
                displayName: 'すべて',
                type: GameConst_1.ItemType.All,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory5,
                frame: 'crop',
                index: 1,
                isSelected: false,
                displayName: 'しょくぶつ',
                type: GameConst_1.ItemType.Plant,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory5,
                frame: 'animal',
                index: 2,
                isSelected: false,
                displayName: 'どうぶつ',
                type: GameConst_1.ItemType.Animal,
            },
        ],
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'harvest',
        index: 2,
        isSelected: false,
        displayName: '収穫',
        category: GameConst_1.ItemCategory.Harvest,
        subMenu: [
            {
                texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
                frame: 'all',
                index: 0,
                isSelected: true,
                displayName: 'すべて',
                type: GameConst_1.ItemType.All,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory6,
                frame: 'crop_product',
                index: 1,
                isSelected: false,
                displayName: 'さくもつ',
                type: GameConst_1.ItemType.Crop,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory6,
                frame: 'animal_product',
                index: 2,
                isSelected: false,
                displayName: 'ちくさんぶつ',
                type: GameConst_1.ItemType.AnimalProduct,
            },
        ],
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'food',
        index: 3,
        isSelected: false,
        displayName: '料理',
        category: GameConst_1.ItemCategory.Food,
        subMenu: [
            {
                texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
                frame: 'all',
                index: 0,
                isSelected: true,
                displayName: 'すべて',
                type: GameConst_1.ItemType.All,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory7,
                frame: 'dish',
                index: 1,
                isSelected: false,
                displayName: 'ごはん',
                type: GameConst_1.ItemType.Dish,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory7,
                frame: 'snack',
                index: 2,
                isSelected: false,
                displayName: 'おやつ',
                type: GameConst_1.ItemType.Snack,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory7,
                frame: 'drink',
                index: 3,
                isSelected: false,
                displayName: 'のみもの',
                type: GameConst_1.ItemType.Drink,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory7,
                frame: 'ingredient',
                index: 4,
                isSelected: false,
                displayName: 'ざいりょう',
                type: GameConst_1.ItemType.Ingredient,
            },
        ],
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'craft',
        index: 4,
        isSelected: false,
        displayName: 'クラフト',
        category: GameConst_1.ItemCategory.Craft,
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'deco',
        index: 5,
        isSelected: false,
        displayName: 'デコ',
        category: GameConst_1.ItemCategory.Deco,
        subMenu: [
            {
                texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
                frame: 'all',
                index: 0,
                isSelected: true,
                displayName: 'すべて',
                type: GameConst_1.ItemType.All,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'table',
                index: 1,
                isSelected: false,
                displayName: 'テーブル',
                type: GameConst_1.ItemType.Table,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'chair',
                index: 2,
                isSelected: false,
                displayName: 'イス',
                type: GameConst_1.ItemType.Chair,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'lighting',
                index: 3,
                isSelected: false,
                displayName: '照明',
                type: GameConst_1.ItemType.Lighting,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'fence',
                index: 4,
                isSelected: false,
                displayName: 'フェンス',
                type: GameConst_1.ItemType.Fence,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'ground',
                index: 5,
                isSelected: false,
                displayName: 'じめん',
                type: GameConst_1.ItemType.Ground,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'garden',
                index: 6,
                isSelected: false,
                displayName: 'ガーデン',
                type: GameConst_1.ItemType.Garden,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'building',
                index: 7,
                isSelected: false,
                displayName: 'たてもの',
                type: GameConst_1.ItemType.Building,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'garden_ornament',
                index: 8,
                isSelected: false,
                displayName: 'おきもの',
                type: GameConst_1.ItemType.GardenOrnament,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'vehicle',
                index: 9,
                isSelected: false,
                displayName: 'のりもの',
                type: GameConst_1.ItemType.Vehicle,
            },
        ],
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'tool',
        index: 6,
        isSelected: false,
        displayName: '道具',
        category: GameConst_1.ItemCategory.Tool,
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'nature',
        index: 7,
        isSelected: false,
        displayName: '自然',
        category: GameConst_1.ItemCategory.Nature,
    },
];
/**
 * Shop buy Category config
 */
exports.ShopBuyCategoryConfig = [
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'all',
        index: 0,
        isSelected: true,
        displayName: 'すべて',
        category: GameConst_1.ItemCategory.All,
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'growth',
        index: 1,
        isSelected: false,
        displayName: '育成',
        category: GameConst_1.ItemCategory.Growth,
        subMenu: [
            {
                texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
                frame: 'all',
                index: 0,
                isSelected: true,
                displayName: 'すべて',
                type: GameConst_1.ItemType.All,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory5,
                frame: 'crop',
                index: 1,
                isSelected: false,
                displayName: 'しょくぶつ',
                type: GameConst_1.ItemType.Plant,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory5,
                frame: 'animal',
                index: 2,
                isSelected: false,
                displayName: 'どうぶつ',
                type: GameConst_1.ItemType.Animal,
            },
        ],
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'deco',
        index: 2,
        isSelected: false,
        displayName: 'デコ',
        category: GameConst_1.ItemCategory.Deco,
        subMenu: [
            {
                texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
                frame: 'all',
                index: 0,
                isSelected: true,
                displayName: 'すべて',
                type: GameConst_1.ItemType.All,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'table',
                index: 1,
                isSelected: false,
                displayName: 'テーブル',
                type: GameConst_1.ItemType.Table,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'chair',
                index: 2,
                isSelected: false,
                displayName: 'イス',
                type: GameConst_1.ItemType.Chair,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'lighting',
                index: 3,
                isSelected: false,
                displayName: '照明',
                type: GameConst_1.ItemType.Lighting,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'fence',
                index: 4,
                isSelected: false,
                displayName: 'フェンス',
                type: GameConst_1.ItemType.Fence,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'ground',
                index: 5,
                isSelected: false,
                displayName: 'じめん',
                type: GameConst_1.ItemType.Ground,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'garden',
                index: 6,
                isSelected: false,
                displayName: 'ガーデン',
                type: GameConst_1.ItemType.Garden,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'building',
                index: 7,
                isSelected: false,
                displayName: 'たてもの',
                type: GameConst_1.ItemType.Building,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'garden_ornament',
                index: 8,
                isSelected: false,
                displayName: 'おきもの',
                type: GameConst_1.ItemType.GardenOrnament,
            },
            {
                texture: TextureKeys_1.TextureKeys.SubCategory9,
                frame: 'vehicle',
                index: 9,
                isSelected: false,
                displayName: 'のりもの',
                type: GameConst_1.ItemType.Vehicle,
            },
        ],
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'facility',
        index: 3,
        isSelected: false,
        displayName: '施設',
        category: GameConst_1.ItemCategory.Facility,
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'tool',
        index: 4,
        isSelected: false,
        displayName: '道具',
        category: GameConst_1.ItemCategory.Tool,
    },
    {
        texture: TextureKeys_1.TextureKeys.CommonCategoryItem,
        frame: 'nature',
        index: 5,
        isSelected: false,
        displayName: '自然',
        category: GameConst_1.ItemCategory.Nature,
    },
];
