"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistryKeys = void 0;
var RegistryKeys;
(function (RegistryKeys) {
    RegistryKeys["FarmState"] = "farmState";
    RegistryKeys["ActionState"] = "actionState";
    RegistryKeys["SeedList"] = "seedList";
    RegistryKeys["DeliveryListState"] = "delivery_list_state";
    RegistryKeys["DelivererState"] = "deliverer_state";
    RegistryKeys["WarehouseState"] = "warehouse_state";
    RegistryKeys["WarehouseCapacityState"] = "warehouse_capacity_state";
    RegistryKeys["UserInfoState"] = "user_info_state";
    RegistryKeys["WarauCoinBalance"] = "warau_coin_balance";
    RegistryKeys["PostState"] = "post_state";
    RegistryKeys["Scrollable"] = "scrollable";
    RegistryKeys["UserIcons"] = "user_icons";
    RegistryKeys["Notice"] = "notice";
    RegistryKeys["Backpack"] = "backpack";
    RegistryKeys["HelperItemState"] = "helper_item_state";
    RegistryKeys["HelperItemDefaultState"] = "helper_item_state_default";
    RegistryKeys["JewelHis"] = "jewel_history";
    RegistryKeys["LoginBonusState"] = "login_bonus_state";
    RegistryKeys["UserNotification"] = "user_notification";
    RegistryKeys["FeatureTutorialState"] = "feature_tutorial_state";
    RegistryKeys["TutorialState"] = "tutorial_state";
    RegistryKeys["VisitState"] = "visit_state";
    RegistryKeys["GachaState"] = "gacha_state";
    RegistryKeys["CampaignState"] = "campaign";
    RegistryKeys["IncreaseOutputCount"] = "increase_output_count";
    RegistryKeys["CampaignData"] = "campaign_data";
    RegistryKeys["HalloweenEntryInfo"] = "halloween_entry_info";
    RegistryKeys["IsTurnOffEventIntroduceModal"] = "is_turn_off_event_introduce_modal";
    RegistryKeys["IsReadWeeklyRanking"] = "is_read_weekly_ranking";
})(RegistryKeys = exports.RegistryKeys || (exports.RegistryKeys = {}));
