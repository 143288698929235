"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FacilityKey = exports.UserAvatarMap = exports.TextureNameMap = exports.TexturePointMap = exports.TextureKeys = void 0;
/* eslint-disable prettier/prettier */
var TextureKeys;
(function (TextureKeys) {
    TextureKeys["LoadingAnimation"] = "loading_animation";
    TextureKeys["CommonAnimation"] = "common_animation";
    TextureKeys["LoadingSpinner"] = "loading_spinner";
    TextureKeys["Environment"] = "environment";
    TextureKeys["ShowBio"] = "show_bio";
    TextureKeys["EditProfile"] = "edit_profile";
    TextureKeys["MainUI"] = "main_ui";
    TextureKeys["IconDisplay"] = "icon_display";
    TextureKeys["IconDisplayReverse"] = "icon_display_reverse";
    TextureKeys["IconBackPack"] = "icon_backpack";
    TextureKeys["IconSubMenu"] = "icon_subMenu";
    TextureKeys["IconNoti"] = "icon_noti";
    TextureKeys["IconNew"] = "icon_new";
    TextureKeys["CommonObject"] = "common_object";
    TextureKeys["CommonItemList"] = "common_item_list";
    TextureKeys["CommonItemDetail"] = "common_item_detail";
    TextureKeys["CommonCategoryItem"] = "common_category_item";
    TextureKeys["SubCategory5"] = "sub_category_5";
    TextureKeys["SubCategory6"] = "sub_category_6";
    TextureKeys["SubCategory7"] = "sub_category_7";
    TextureKeys["SubCategory9"] = "sub_category_9";
    TextureKeys["WareHouseMenu"] = "warehouse_menu";
    TextureKeys["ShopMenu"] = "shop_menu";
    TextureKeys["RepeatWareHouse"] = "repeat_warehouse";
    TextureKeys["ProfileIcon1"] = "profile_icon_1";
    TextureKeys["ProfileIcon2"] = "profile_icon_2";
    TextureKeys["ProfileIcon3"] = "profile_icon_3";
    TextureKeys["ProfileIcon4"] = "profile_icon_4";
    TextureKeys["Tree"] = "tree";
    TextureKeys["Rock"] = "rock";
    TextureKeys["ProfileIcon"] = "profile_icon";
    TextureKeys["IconCancel"] = "icon_cancel";
    TextureKeys["IconAccept"] = "icon_accept";
    TextureKeys["IconStore"] = "icon_store";
    TextureKeys["IconRotate"] = "icon_rotate";
    TextureKeys["IconDone"] = "icon_done";
    TextureKeys["IconHoldDownArrow"] = "icon_hold_down_arrow";
    TextureKeys["IconTopLeftArrow"] = "icon_top_left_arrow";
    TextureKeys["IconTopRightArrow"] = "icon_top_right_arrow";
    TextureKeys["IconBottomLeftArrow"] = "icon_bottom_left_arrow";
    TextureKeys["IconBottomRightArrow"] = "icon_bottom_right_arrow";
    TextureKeys["DashedTile"] = "dashed_tile";
    TextureKeys["BlueStripedBg"] = "blue_striped_background";
    TextureKeys["InfoModal"] = "info_modal";
    TextureKeys["Dummy"] = "dummy";
    TextureKeys["LockedArea"] = "locked_area";
    TextureKeys["DialogFrame"] = "dialog_frame";
    TextureKeys["CloseButton"] = "close_button";
    TextureKeys["RepeatBlueStripe"] = "repeat_blue_stripe";
    TextureKeys["Axe"] = "axe";
    TextureKeys["Twinkle"] = "twinkle";
    TextureKeys["Flicker"] = "flicker";
    TextureKeys["CleanLandCropAnim"] = "clean_land_crops_animation";
    TextureKeys["CleanLandAnimalAnim"] = "clean_land_animal_animation";
    TextureKeys["UpText"] = "up_text";
    TextureKeys["RedArrowUp"] = "red_arrow_up";
    TextureKeys["KitchenUI"] = "kitchen_ui";
    TextureKeys["CraftShopUI"] = "craftshop_ui";
    TextureKeys["GuideUI"] = "guide_ui";
    TextureKeys["Profile"] = "profile";
    TextureKeys["IconLevel"] = "icon_level";
    TextureKeys["RandomDrop"] = "random_drop";
    TextureKeys["HelpUI"] = "help_ui";
    TextureKeys["IconTap"] = "icon_tap";
    TextureKeys["Control"] = "control";
    TextureKeys["IconLock"] = "icon_lock";
    TextureKeys["TwinkleHarvestGlitter"] = "twinkle_harvest_glitter";
    TextureKeys["TwinkleHarvestCircle"] = "twinkle_harvest_circle";
    // Facilities
    TextureKeys["Warehouse"] = "warehouse";
    /** img_growth */
    TextureKeys["GrowthHarvest"] = "growth_harvest";
    /** Type of crops */
    TextureKeys["WheatProduct"] = "wheat_product";
    TextureKeys["StrawberryProduct"] = "straw_berry_product";
    TextureKeys["RadishProduct"] = "radish_product";
    TextureKeys["CornProduct"] = "corn_product";
    TextureKeys["BeanProduct"] = "bean_product";
    TextureKeys["CarrotProduct"] = "carrot_product";
    TextureKeys["TomatoProduct"] = "tomato_product";
    TextureKeys["RiceProduct"] = "rice_product";
    TextureKeys["PotatoProduct"] = "potato_product";
    TextureKeys["OliveProduct"] = "olive_product";
    TextureKeys["OnionProduct"] = "onion_product";
    TextureKeys["CacaoProduct"] = "cacao_product";
    /** Type of animal */
    TextureKeys["ChickenProduct"] = "chicken_product";
    TextureKeys["CowProduct"] = "cow_product";
    TextureKeys["SheepProduct"] = "sheep_product";
    TextureKeys["PigProduct"] = "pig_product";
    TextureKeys["BeeProduct"] = "bee_product";
    TextureKeys["XmasSheepProduct"] = "xmas_sheep_product";
    /** Related to crops */
    TextureKeys["CropSeed"] = "crops_seed";
    TextureKeys["HarvestTool"] = "harvest_tool";
    TextureKeys["CleanCropTool"] = "clean_crop_tool";
    TextureKeys["DropletSmall"] = "droplet_small";
    TextureKeys["WateringCan"] = "watering_can";
    TextureKeys["WheatSeedItem"] = "wheat_seed_item";
    TextureKeys["StrawberrySeedItem"] = "strawberry_seed_item";
    TextureKeys["RadishSeedItem"] = "radish_seed_item";
    TextureKeys["CornSeedItem"] = "corn_seed_item";
    TextureKeys["BeanSeedItem"] = "bean_seed_item";
    TextureKeys["CarrotSeedItem"] = "carrot_seed_item";
    TextureKeys["TomatoSeedItem"] = "tomato_seed_item";
    TextureKeys["RiceSeedItem"] = "rice_seed_item";
    TextureKeys["PotatoSeedItem"] = "potato_seed_item";
    TextureKeys["OliveSeedItem"] = "olive_seed_item";
    TextureKeys["OnionSeedItem"] = "onion_seed_item";
    TextureKeys["CacaoSeedItem"] = "cacao_seed_item";
    TextureKeys["IconCropInfo"] = "icon_crops_info";
    TextureKeys["ToolCropInfo"] = "tool_crops_info";
    TextureKeys["HarvestCropInfo"] = "harvest_crops_info";
    /** Related to animal */
    TextureKeys["FoodBag"] = "food_bag";
    TextureKeys["FoodSeed"] = "food_seed";
    TextureKeys["CleanAnimalTool"] = "clean_animal_tool";
    TextureKeys["ChickenSeedItem"] = "chicken_seed_item";
    TextureKeys["CowSeedItem"] = "cow_seed_item";
    TextureKeys["SheepSeedItem"] = "sheep_seed_item";
    TextureKeys["PigSeedItem"] = "pig_seed_item";
    TextureKeys["BeeSeedItem"] = "bee_seed_item";
    TextureKeys["XmasSheepSeedItem"] = "xmas_sheep_seed_item";
    TextureKeys["IconAnimalInfo"] = "icon_animal_info";
    TextureKeys["ToolAnimalInfo"] = "tool_animal_info";
    // Delivery
    TextureKeys["DeliveryBoard"] = "delivery_board";
    TextureKeys["DeliveryUI"] = "delivery_ui";
    TextureKeys["DeliveryMaterialListIcon"] = "delivery_material_list_icon";
    TextureKeys["DeliveryMaterialBoard"] = "delivery_material_board";
    TextureKeys["DeliveryMaterialCaret"] = "delivery_material_caret";
    TextureKeys["DeliveryAbleToCook"] = "delivery_able_to_cook";
    TextureKeys["DeliveryUnableToCook"] = "delivery_unable_to_cook";
    TextureKeys["IconColon"] = "icon_colon";
    TextureKeys["IconJewel"] = "icon_jewel";
    TextureKeys["IconExp"] = "icon_exp";
    TextureKeys["IconClock"] = "icon_clock";
    TextureKeys["IconQuantity"] = "icon_quantity";
    TextureKeys["Rarity"] = "rarity";
    TextureKeys["IconCommon"] = "icon_common";
    TextureKeys["Pichitaro"] = "Pichitaro";
    /** Menu */
    TextureKeys["MenuIcon"] = "menu_icon";
    TextureKeys["RepeatMenu"] = "repeat_menu";
    TextureKeys["IconNewMenu"] = "icon_new_menu";
    // Post
    TextureKeys["PostUI"] = "post_ui";
    TextureKeys["PostBgrTitle"] = "post_bgrtitle";
    TextureKeys["PostDecoTopLeft"] = "post_deco_topleft";
    /** Mission */
    TextureKeys["MissionText"] = "mission_text";
    // Information
    TextureKeys["InformationUI"] = "information_ui";
    // Background images
    TextureKeys["FarmBackground1"] = "farm_background_1";
    TextureKeys["FarmBackground2"] = "farm_background_2";
    TextureKeys["FarmBackground3"] = "farm_background_3";
    TextureKeys["FarmBackground4"] = "farm_background_4";
    TextureKeys["FarmBackground5"] = "farm_background_5";
    TextureKeys["FarmBackground6"] = "farm_background_6";
    TextureKeys["FarmBackground7"] = "farm_background_7";
    TextureKeys["FarmBackground8"] = "farm_background_8";
    TextureKeys["FarmBackground9"] = "farm_background_9";
    TextureKeys["FarmBackground10"] = "farm_background_10";
    TextureKeys["FarmBackground11"] = "farm_background_11";
    TextureKeys["FarmBackground12"] = "farm_background_12";
    TextureKeys["FarmBackground13"] = "farm_background_13";
    TextureKeys["FarmBackground14"] = "farm_background_14";
    TextureKeys["FarmBackground15"] = "farm_background_15";
    TextureKeys["FarmBackground16"] = "farm_background_16";
    TextureKeys["FarmBackground17"] = "farm_background_17";
    TextureKeys["FarmBackground18"] = "farm_background_18";
    TextureKeys["FarmBackground19"] = "farm_background_19";
    TextureKeys["FarmBackground20"] = "farm_background_20";
    TextureKeys["FarmBackground21"] = "farm_background_21";
    TextureKeys["FarmBackground22"] = "farm_background_22";
    TextureKeys["FarmBackground23"] = "farm_background_23";
    TextureKeys["FarmBackground24"] = "farm_background_24";
    TextureKeys["FarmBackground25"] = "farm_background_25";
    TextureKeys["FarmBackground26"] = "farm_background_26";
    TextureKeys["FarmBackground27"] = "farm_background_27";
    TextureKeys["FarmBackground28"] = "farm_background_28";
    TextureKeys["FarmBackground29"] = "farm_background_29";
    TextureKeys["FarmBackground30"] = "farm_background_30";
    TextureKeys["FarmBackground31"] = "farm_background_31";
    TextureKeys["FarmBackground32"] = "farm_background_32";
    /** Currency shop */
    TextureKeys["CurrencyShop"] = "currency_shop";
    TextureKeys["BottomDecoJewelShopHis"] = "bottom_deco";
    TextureKeys["WarauCoinBackground"] = "warau_coin_background";
    TextureKeys["ButtonChargeWarauCoin"] = "button_charge_warau_coin";
    TextureKeys["ButtonAboutCurrency"] = "button_about_currency";
    TextureKeys["UnderlineWhatIsWarauCoin"] = "underline_what_is_waraucoin";
    // Tutorial
    TextureKeys["Tutorial"] = "tutorial";
    /** Helper item tool */
    TextureKeys["NormalWateringCan"] = "normal_watering_can";
    TextureKeys["GoldWateringCan"] = "gold_watering_can";
    TextureKeys["SilverWateringCan"] = "silver_watering_can";
    TextureKeys["BirdShapedWateringCan"] = "bird_shaped_watering_can";
    TextureKeys["NormalFeed"] = "normal_feed";
    TextureKeys["SilverFeed"] = "silver_feed";
    TextureKeys["GoldFeed"] = "gold_feed";
    TextureKeys["OneCupOfDeluxFeeds"] = "one_cup_of_delux_feeds";
    TextureKeys["PickAxe"] = "pick_axe";
    TextureKeys["Shovel"] = "shovel";
    /** Level up */
    TextureKeys["LevelUpBoard"] = "level_up_board";
    TextureKeys["LevelUpAnim"] = "level_up_anim";
    TextureKeys["ConfettiBlue"] = "confetti_blue";
    TextureKeys["ConfettiRed"] = "confetti_red";
    TextureKeys["ConfettiYellow"] = "confetti_yellow";
    /** Login Bonus */
    TextureKeys["LoginBonusUI"] = "login_bonus_ui";
    /** Gacha */
    TextureKeys["GachaUI"] = "gacha_ui";
    /** Album */
    TextureKeys["AlbumBanner"] = "album_banner";
    TextureKeys["IconStory"] = "icon_story";
    TextureKeys["AlbumItemFrame"] = "album_item_frame";
    TextureKeys["AlbumItemBox"] = "album_item_box";
    TextureKeys["AlbumCategoryBg"] = "album_category_bg";
    TextureKeys["AlbumTopListBg"] = "album_top_list_bg";
    TextureKeys["AlbumRepeatBg"] = "album_repeat_bg";
    TextureKeys["AlbumItemBoxEmpty"] = "album_item_box_empty";
    /** Seeding arrow */
    TextureKeys["SeedingArrow"] = "seeding_arrow";
    /** Story */
    TextureKeys["StoryBgCurrentFarm"] = "story_bg_current_farm";
    TextureKeys["StoryBgIsland"] = "story_bg_island";
    TextureKeys["StoryBgIslandAndTrain"] = "story_bg_island_and_train";
    TextureKeys["StoryBgMimosaRoad"] = "story_bg_mimosaroad";
    TextureKeys["StoryBgOldFarm"] = "story_bg_old_farm";
    TextureKeys["StoryBgSea"] = "story_bg_sea";
    TextureKeys["StoryBgSeaAndTrain"] = "story_bg_sea_and_train";
    TextureKeys["StoryBgSleepingChiffon"] = "story_bg_sleeping_chiffon";
    TextureKeys["StoryPhotoChiffon"] = "story_photo_chiffon";
    TextureKeys["StoryCharacterChiffon"] = "story_character_chiffon";
    TextureKeys["StoryCharacterChiffon1"] = "story_character_chiffon1";
    TextureKeys["StoryCharacterChiffon2"] = "story_character_chiffon2";
    TextureKeys["StoryCharacterMuffin"] = "story_character_muffin";
    TextureKeys["StoryCharacterMuffin1"] = "story_character_muffin1";
    TextureKeys["StoryCharacterMuffin2"] = "story_character_muffin2";
    TextureKeys["StoryCharacterMuffin3"] = "story_character_muffin3";
    TextureKeys["StoryBtnSkip"] = "story_btn_skip";
    TextureKeys["StoryFrameCharacterName"] = "story_frame_character_name";
    TextureKeys["StoryFrameTalk"] = "story_frame_talk";
    TextureKeys["StoryIconArrowDown"] = "story_icon_arrow_down";
    TextureKeys["EventBanner"] = "event_banner";
    TextureKeys["EventJewelShop"] = "event_jewel_shop";
    TextureKeys["LimitedGacha"] = "limited_gacha";
    TextureKeys["NewReleaseShop"] = "new_release_shop";
    TextureKeys["EventBannerGacha"] = "event_banner_gacha";
    TextureKeys["BannerGachaByDate"] = "banner_gacha_by_date";
    TextureKeys["GachaBubble"] = "gacha_bubble";
    TextureKeys["DeliveryStepUpModal"] = "delivery_step_up_modal";
    TextureKeys["DeliveryStepUpCompleted"] = "delivery_step_up_completed";
    TextureKeys["DeliveryHalloweenTitle"] = "delivery_halloween_title";
    TextureKeys["DeliveryCampaignBanner"] = "delivery_campaign_banner";
    TextureKeys["DeliveryCampaignInfoBox"] = "delivery_campaign_info_box";
    TextureKeys["EventIntroduceModal"] = "event_introduce_modal";
    TextureKeys["FrameFeedProgress"] = "frame_feed_progress";
    TextureKeys["SpeedUpButton"] = "speed_up_button";
    TextureKeys["SpeedUpItemsDialogTop"] = "speed_up_items_dialog_top";
    TextureKeys["EventDeliveryColonUp"] = "event_delivery_colon_up";
    TextureKeys["TwitterBanner"] = "twitter_banner";
    // Visit farm
    TextureKeys["IconBoots"] = "icon_boots";
    TextureKeys["IconUserLevel"] = "icon_user_level";
    TextureKeys["IconBeigeArrow"] = "icon_beige_arrow";
    TextureKeys["BgSingleMenu"] = "bg_single_menu";
    TextureKeys["BtnBackToFarm"] = "btn_back_to_farm";
    TextureKeys["HeaderOtherUserStatus"] = "header_other_user_status";
    TextureKeys["BgHeaderDialogVisit"] = "bg_header_dialog_visit";
    TextureKeys["SpeedUpItemsDialogBottom"] = "speed_up_items_dialog_bottom";
    TextureKeys["SpeedUpItemsDetailBottom"] = "speed_up_items_detail_bottom";
    TextureKeys["IconLikeNumber"] = "icon_like_number";
    TextureKeys["BtnLikeActive"] = "btn_like_active";
    TextureKeys["BtnLikeInActive"] = "btn_like_in_active";
    TextureKeys["TouchButtonParticle"] = "touch_button_particle";
    TextureKeys["IconLikeActive"] = "icon_like_active";
    TextureKeys["NotiIconIvyLeaveTopRight"] = "noti_icon_ivy_leave_top_right";
    TextureKeys["NotiIconIvyLeaveBottomRight"] = "noti_icon_ivy_leave_bottom_right";
    TextureKeys["TooltipBox"] = "jewel_history_tooltip_box";
    TextureKeys["MaterialTooltipBox"] = "material_tooltip_box";
    TextureKeys["EntryModalBackground"] = "entry_modal_background";
    TextureKeys["EntryModalBanner"] = "entry_modal_banner";
    TextureKeys["RankingModalBackground"] = "ranking_modal_background";
    TextureKeys["RankingDuringEventModalBanner"] = "ranking_during_event_modal_banner";
    TextureKeys["RankingEndedEventModalBanner"] = "ranking_ended_event_modal_banner";
    TextureKeys["RankingTop10Tab"] = "ranking_top10_tab";
    TextureKeys["RankingTop10TabActive"] = "ranking_top10_tab_active";
    TextureKeys["RankingYourRankTab"] = "ranking_your_rank_tab";
    TextureKeys["RankingYourRankTabActive"] = "ranking_your_rank_tab_active";
    TextureKeys["RankingUserIcon"] = "ranking_user_icon";
    TextureKeys["RankingItemArrow"] = "ranking_item_arrow";
    // weekly ranking
    TextureKeys["WeeklyRankingDialogBackground"] = "weekly_ranking_dialog_background";
    TextureKeys["WeeklyRankingInfoModal"] = "weekly_ranking_info_modal";
    TextureKeys["WeeklyRankingTabThisWeekActive"] = "weekly_ranking_tab_this_week_active";
    TextureKeys["WeeklyRankingTabThisWeekInActive"] = "weekly_ranking_tab_this_week_inactive";
    TextureKeys["WeeklyRankingTabLastWeekActive"] = "weekly_ranking_tab_last_week_active";
    TextureKeys["WeeklyRankingTabLastWeekInActive"] = "weekly_ranking_tab_last_week_inactive";
})(TextureKeys = exports.TextureKeys || (exports.TextureKeys = {}));
exports.TexturePointMap = {
    locked_area: [323, 44, 627, 194, 322, 345, 23, 194],
};
/** Key-value of name object and texture key */
exports.TextureNameMap = {
    // Seeds
    小麦のタネ: TextureKeys.WheatSeedItem,
    いちごのタネ: TextureKeys.StrawberrySeedItem,
    ビートのタネ: TextureKeys.RadishSeedItem,
    とうもろこしのタネ: TextureKeys.CornSeedItem,
    豆のタネ: TextureKeys.BeanSeedItem,
    にんじんのタネ: TextureKeys.CarrotSeedItem,
    トマトのタネ: TextureKeys.TomatoSeedItem,
    お米のタネ: TextureKeys.RiceSeedItem,
    じゃがいものタネイモ: TextureKeys.PotatoSeedItem,
    オリーブのタネ: TextureKeys.OliveSeedItem,
    たまねぎのタネ: TextureKeys.OnionSeedItem,
    カカオのタネ: TextureKeys.CacaoSeedItem,
    ひよこさん: TextureKeys.ChickenSeedItem,
    子ブタさん: TextureKeys.PigSeedItem,
    子ヒツジさん: TextureKeys.SheepSeedItem,
    子ウシさん: TextureKeys.CowSeedItem,
    ハチさん: TextureKeys.BeeSeedItem,
    クリスマスメリーさん: TextureKeys.XmasSheepSeedItem,
    // User icons
    アイコン_1: TextureKeys.ProfileIcon1,
    アイコン_2: TextureKeys.ProfileIcon2,
    アイコン_3: TextureKeys.ProfileIcon3,
    アイコン_4: TextureKeys.ProfileIcon4,
    // Helper item
    オノ: TextureKeys.Axe,
    ピッケル: TextureKeys.PickAxe,
    スコップ: TextureKeys.Shovel,
    銀のジョウロ: TextureKeys.SilverWateringCan,
    いいエサ: TextureKeys.SilverFeed,
    金のジョウロ: TextureKeys.GoldWateringCan,
    お高級なエサ: TextureKeys.GoldFeed,
    とりさんの水差し: TextureKeys.BirdShapedWateringCan,
    'お高級なエサ（1杯）': TextureKeys.OneCupOfDeluxFeeds,
    // Gacha item
    デイリーガチャ: TextureKeys.BannerGachaByDate,
};
exports.UserAvatarMap = {
    1: TextureKeys.ProfileIcon1,
    2: TextureKeys.ProfileIcon2,
    3: TextureKeys.ProfileIcon3,
    4: TextureKeys.ProfileIcon4,
};
var FacilityKey;
(function (FacilityKey) {
    FacilityKey["NormalHouse"] = "normal_house";
    FacilityKey["Shop"] = "shop";
    FacilityKey["BluePost"] = "blue_post";
    FacilityKey["Warehouse"] = "warehouse";
    FacilityKey["SmallDeliver"] = "small_deliverer";
    FacilityKey["Craftshop"] = "raccoons_craftshop";
    FacilityKey["Kitchen"] = "kitchen";
    FacilityKey["Farm"] = "farm";
    FacilityKey["Pasture"] = "pasture";
})(FacilityKey = exports.FacilityKey || (exports.FacilityKey = {}));
