<template>
  <div id="maintenance-dialog">
    <div ref="modal" class="modal">
      <div class="maintenance-title">メンテナンス中</div>
      <div class="maintenance-content scrollbar" v-html="content"></div>
      <div class="button button-go-warau flex-center" @click="goToWarauSite">
        ワラウに戻る
      </div>
    </div>
  </div>
</template>

<script land="ts">
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      content: '',
    };
  },
  methods: {
    goToWarauSite() {
      location.href = 'https://www.warau.jp';
    },
  },
  mounted() {
    const messageTag = document.getElementsByName('maintenance_message')[0];
    this.content = messageTag.getAttribute('content');
  },
});
</script>

<style scoped>
#maintenance-dialog {
  color: #8c623a;
  display: flex;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
}

.modal {
  position: relative;
  background-color: #faf8ee;
  margin: auto;
  width: 88%;
  height: 82%;
  border-image-source: url('/images/game/common/img_dialog_frame.png');
  border-style: solid;
  border-radius: 7%;
  border-image-slice: 95 70;
  border-image-width: 9%;
  border-image-outset: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maintenance-title {
  margin-top: 7%;
  margin-bottom: 5%;
  font-size: 1em;
  font-weight: 900;
  display: flex;
  align-items: center;
}

.maintenance-content {
  margin-top: 1%;
  width: 90%;
  height: 63%;
  padding: 20px 15px;
  background-color: #ffffff;
  font-weight: 100;
  font-size: 0.8em;
  font-family: 'roundedMplus', sans-serif;
  line-height: 1.5em;
  letter-spacing: 0.05em;
  border-top: 1px dashed #d9af70;
  border-bottom: 1px dashed #d9af70;
  overflow: auto;
}

.button-go-warau {
  margin-top: 7%;
  width: 81%;
  height: 8.5%;
  background-color: #58bba7;
  border-radius: 0.6em;
  -webkit-box-shadow: 0 0.3em 0 0 #45a38e;
  -moz-box-shadow: 0 0.3em 0 0 #45a38e;
  box-shadow: 0 0.3em 0 0 #45a38e;
  color: #e4faf4;
  font-size: 1.25em;
  font-weight: 900;
}
</style>
