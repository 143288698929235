"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Events = void 0;
exports.Events = {
    CategoryUpdateFilter: 'category_update_filter',
    ShopSellWarehouseItem: 'shop_sell_warehouse_item',
    RefreshWarehouseItem: 'refresh_warehouse_item',
    UpdateUserAvatar: 'update_user_avatar',
    KitchenRefresh: 'kitchen_refresh',
    CraftShopFacilityUpdateStatus: 'craftshop_facility_update_status',
    CraftShopRefresh: 'craft_shop_refresh',
    CraftShopMakeOpenableSlot: 'craft_shop_open_slot',
    CraftShopCreate: 'craft_shop_create',
    CraftShopMakeTargetSlot: 'craft_shop_make_target_slot',
    ReceiveDeliveryPost: 'receive_delivery_post',
    //** Weekly Ranking */
    WeeklyRankingTabChange: 'weekly_ranking_tab_change',
    NotiTabChange: 'noti_tab_change',
    NotiShowItemDetail: 'noti_show_item_detail',
    NotiCloseItemDetail: 'noti_close_item_detail',
    ListViewStartDrag: 'list_view_start_drag',
    RefreshHelpMenu: 'refresh_help_menu',
    /** PostUI */
    TabChange: 'change_tab_post',
    ReceivePostBtnClick: 'receive_post',
    ReceivePostBtnClicked: 'received_post',
    /** Show error dialog when API failed */
    ApiErrorDialog: 'api_error_dialog',
    MaintenanceDialog: 'maintenance_dialog',
    CareError: 'care_error',
    UpdateUserExp: 'update_user_exp',
    // Farm
    FarmDeployComplete: 'farm_deploy_completle',
    /** Delivery bird */
    BirdIn: 'bird_in',
    BirdOut: 'bird_out',
    ReceiveDeliveryReward: 'receive_delivery_reward',
    HideDeliveryList: 'hide_delivery_list',
    ReceiveRewardDeliveryAnim: 'receive_reward_delivery_anim',
    ReceiveRewardDelivery: 'receive_reward_delivery',
    /** RightMenu hide or show notification/new mark */
    NotiMarkChange: 'noti_mark_change',
    /** Currency Shop */
    RefreshShopColon: 'refresh_shop_colon',
    RefreshShopJewel: 'refresh_shop_jewel',
    /** HelperItem */
    UpdateHelperItemState: 'update_helper_item_state',
    /** Land unit */
    EnableBackpack: 'enable_backpack',
    // Land unit
    LandUnitShowRandomDrop: 'land_unit_show_random_drop',
    DeployedFromShop: 'deployed_from_shop',
    /** Mission */
    MissionCategoryUpdate: 'mission_category_update',
    MissionUpdateList: 'mission_update_list',
    MissionCompleted: 'mission_completed',
    /** Tutorial */
    TutorialCompleted: 'tutorial_completed',
    DeployedFacility: 'deployed_facility',
    /** Time related */
    RefreshDateDialog: 'refresh_date_dialog',
    /** Alert dialog */
    AlertDialog: 'alert_dialog',
    /** Capacity update */
    CapacityUpdate: 'capacity_update',
    /** Capacity render*/
    CapacityRendered: 'capacity_rendered',
    /** This event will fire when showing the feedTool of the Crop/Animal */
    ShowFeedProgress: 'show_feed_progress',
    /**
     * Emit every time the harvestTime change when Crop/Animal in feedable state
     */
    HarvestTimeUpdate: 'harvest_time_update',
    /** Google Analytics Events */
    GABuyJewelSuccess: 'buy_jewel_success',
    GADrawGacha: 'draw_gacha',
    GAOpenJewelShop: 'open_jewel_shop',
    GAOpenWarau: 'open_warau',
    GAClickBunnerDecoContest20220221: 'CL_farm_bnr_decoContest_202202',
    /**Capacity state banner gacha */
    CapacityStateBannerGacha: 'capacity_state_banner_gacha',
    ShopItemsRendered: 'shop_items_rendered',
    /** Harvesting events */
    GrowthActionEnd: 'growth_action_end',
    Planting: 'planting',
    Harvesting: 'harvesting',
    ShowJewelHistoryTooltip: 'show_jewel_history_tooltip',
    OnMaterialItemClick: 'on_material_item_click',
    UpdateGachaButton: 'update_gacha_button',
};
