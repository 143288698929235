"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeDurationAnimationMove = exports.CampaignID = exports.GachaID = exports.GachaCostType = exports.MIN_KAGO = exports.CLICK_CLEARANCE_RANGE = exports.LIKE_MAXIMUM = exports.TEXT_LIKE_MAXIMUM = exports.JEWEL_MAX_NUMBER = exports.COLON_MAX_NUMBER = exports.HelperItemTypeNameMap = exports.FeatureTutorialType = exports.TutorialType = exports.UserRegisterScreen = exports.HelperItemType = exports.NotiMarkChange = exports.FieldType = exports.NotiLabelType = exports.MissionCategoryID = exports.ItemType = exports.ItemCategory = exports.ZoomLevel = exports.ItemRarity = exports.ItemTag = exports.CurrencyType = exports.GrowthActions = exports.SENTRY_DSN = exports.PARAM_SHARE_PROFILE = exports.DAYS_CONDITION = exports.AVATAR_RADIUS = exports.REQUIRED_LEVEL_BACKPACK = exports.JEWEL_SKIP_COUNTDOWN = exports.GAME_RESOLUTION = exports.LAND_COL_COUNT = exports.LAND_ROW_COUNT = exports.GAME_RATIO = exports.TILE_WIDTH = exports.SERVER_ADDRESS = void 0;
/** Server address */
exports.SERVER_ADDRESS = '/api/';
/** Width of a tile */
exports.TILE_WIDTH = 60;
/** Ratio of game screen = default_width / default_height */
exports.GAME_RATIO = 750 / 1112;
/** Count of row in the land map */
exports.LAND_ROW_COUNT = 21;
/** Count of column the the land map */
exports.LAND_COL_COUNT = 21;
/** Resolution number of the game */
exports.GAME_RESOLUTION = 2;
/** Jewel amount for skipping change order delivery */
exports.JEWEL_SKIP_COUNTDOWN = 5;
/** Required level for backpack usage */
exports.REQUIRED_LEVEL_BACKPACK = 4;
/** Radius of avatar image */
exports.AVATAR_RADIUS = 16;
/** Days condition to display mark NEW in noti item */
exports.DAYS_CONDITION = 3;
/** Param of profile sharing url */
exports.PARAM_SHARE_PROFILE = 'userId';
/** Sentry.io report dsn */
exports.SENTRY_DSN = 'https://255bd0ef3bc44be4b4ec3756e32327c0@o1095789.ingest.sentry.io/6115797';
var GrowthActions;
(function (GrowthActions) {
    GrowthActions["Planting"] = "planting";
    GrowthActions["Feeding"] = "feeding";
    GrowthActions["Harvesting"] = "harvesting";
    GrowthActions["Cleaning"] = "cleaning";
})(GrowthActions = exports.GrowthActions || (exports.GrowthActions = {}));
var CurrencyType;
(function (CurrencyType) {
    CurrencyType["Colon"] = "colon";
    CurrencyType["Jewel"] = "jewel";
})(CurrencyType = exports.CurrencyType || (exports.CurrencyType = {}));
var ItemTag;
(function (ItemTag) {
    ItemTag["New"] = "new";
    ItemTag["Recommended"] = "recommended";
})(ItemTag = exports.ItemTag || (exports.ItemTag = {}));
var ItemRarity;
(function (ItemRarity) {
    ItemRarity["A"] = "A";
    ItemRarity["B"] = "B";
    ItemRarity["C"] = "C";
    ItemRarity["S"] = "S";
})(ItemRarity = exports.ItemRarity || (exports.ItemRarity = {}));
exports.ZoomLevel = [1, 1.4, 1.9, 0.4, 0.6];
var ItemCategory;
(function (ItemCategory) {
    ItemCategory[ItemCategory["All"] = 0] = "All";
    ItemCategory[ItemCategory["Colon"] = 1] = "Colon";
    ItemCategory[ItemCategory["Jewel"] = 2] = "Jewel";
    ItemCategory[ItemCategory["GachaPass"] = 3] = "GachaPass";
    ItemCategory[ItemCategory["Growth"] = 4] = "Growth";
    ItemCategory[ItemCategory["Harvest"] = 5] = "Harvest";
    ItemCategory[ItemCategory["Food"] = 6] = "Food";
    ItemCategory[ItemCategory["Craft"] = 7] = "Craft";
    ItemCategory[ItemCategory["Deco"] = 8] = "Deco";
    ItemCategory[ItemCategory["Facility"] = 9] = "Facility";
    ItemCategory[ItemCategory["Tool"] = 10] = "Tool";
    ItemCategory[ItemCategory["Nature"] = 11] = "Nature";
})(ItemCategory = exports.ItemCategory || (exports.ItemCategory = {}));
var ItemType;
(function (ItemType) {
    ItemType[ItemType["All"] = 0] = "All";
    ItemType[ItemType["Colon"] = 1] = "Colon";
    ItemType[ItemType["Jewel"] = 2] = "Jewel";
    ItemType[ItemType["GachaPass"] = 3] = "GachaPass";
    ItemType[ItemType["Plant"] = 4] = "Plant";
    ItemType[ItemType["Animal"] = 5] = "Animal";
    ItemType[ItemType["Crop"] = 6] = "Crop";
    ItemType[ItemType["AnimalProduct"] = 7] = "AnimalProduct";
    ItemType[ItemType["FoodOther"] = 8] = "FoodOther";
    ItemType[ItemType["Dish"] = 9] = "Dish";
    ItemType[ItemType["Snack"] = 10] = "Snack";
    ItemType[ItemType["Drink"] = 11] = "Drink";
    ItemType[ItemType["Ingredient"] = 12] = "Ingredient";
    ItemType[ItemType["DecoOther"] = 13] = "DecoOther";
    ItemType[ItemType["Table"] = 14] = "Table";
    ItemType[ItemType["Chair"] = 15] = "Chair";
    ItemType[ItemType["Lighting"] = 16] = "Lighting";
    ItemType[ItemType["Fence"] = 17] = "Fence";
    ItemType[ItemType["Ground"] = 18] = "Ground";
    ItemType[ItemType["Garden"] = 19] = "Garden";
    ItemType[ItemType["Building"] = 20] = "Building";
    ItemType[ItemType["GardenOrnament"] = 21] = "GardenOrnament";
    ItemType[ItemType["Vehicle"] = 22] = "Vehicle";
    ItemType[ItemType["Facility"] = 24] = "Facility";
    ItemType[ItemType["Tool"] = 25] = "Tool";
    ItemType[ItemType["Craft"] = 26] = "Craft";
})(ItemType = exports.ItemType || (exports.ItemType = {}));
var MissionCategoryID;
(function (MissionCategoryID) {
    MissionCategoryID[MissionCategoryID["Normal"] = 1] = "Normal";
    MissionCategoryID[MissionCategoryID["Daily"] = 2] = "Daily";
})(MissionCategoryID = exports.MissionCategoryID || (exports.MissionCategoryID = {}));
var NotiLabelType;
(function (NotiLabelType) {
    NotiLabelType["Release"] = "release";
    NotiLabelType["Event"] = "event";
    NotiLabelType["Maintenance"] = "maintenance";
    NotiLabelType["Normal"] = "notice";
})(NotiLabelType = exports.NotiLabelType || (exports.NotiLabelType = {}));
var FieldType;
(function (FieldType) {
    FieldType["Obstacle"] = "obstacle";
    FieldType["Normal"] = "normal";
    FieldType["Ground"] = "ground";
    FieldType["Fence"] = "fence";
})(FieldType = exports.FieldType || (exports.FieldType = {}));
var NotiMarkChange;
(function (NotiMarkChange) {
    NotiMarkChange["Notice"] = "notice";
    NotiMarkChange["Post"] = "post";
    NotiMarkChange["Mission"] = "mission";
    NotiMarkChange["Friend"] = "friend";
})(NotiMarkChange = exports.NotiMarkChange || (exports.NotiMarkChange = {}));
var HelperItemType;
(function (HelperItemType) {
    HelperItemType[HelperItemType["Axe"] = 1] = "Axe";
    HelperItemType[HelperItemType["PickAxe"] = 2] = "PickAxe";
    HelperItemType[HelperItemType["Shovel"] = 3] = "Shovel";
    HelperItemType[HelperItemType["WateringCan"] = 4] = "WateringCan";
    HelperItemType[HelperItemType["Feed"] = 5] = "Feed";
    HelperItemType[HelperItemType["CropSpeedUpItem"] = 6] = "CropSpeedUpItem";
    HelperItemType[HelperItemType["AnimalSpeedUpItem"] = 7] = "AnimalSpeedUpItem";
})(HelperItemType = exports.HelperItemType || (exports.HelperItemType = {}));
var UserRegisterScreen;
(function (UserRegisterScreen) {
    UserRegisterScreen[UserRegisterScreen["SetAvatarScreen"] = 1] = "SetAvatarScreen";
    UserRegisterScreen[UserRegisterScreen["SetNicknameScreen"] = 2] = "SetNicknameScreen";
    UserRegisterScreen[UserRegisterScreen["CheckScreen"] = 3] = "CheckScreen";
})(UserRegisterScreen = exports.UserRegisterScreen || (exports.UserRegisterScreen = {}));
var TutorialType;
(function (TutorialType) {
    TutorialType[TutorialType["First"] = 1] = "First";
    TutorialType[TutorialType["Feature"] = 2] = "Feature";
})(TutorialType = exports.TutorialType || (exports.TutorialType = {}));
var FeatureTutorialType;
(function (FeatureTutorialType) {
    FeatureTutorialType[FeatureTutorialType["Warehouse"] = 3] = "Warehouse";
    FeatureTutorialType[FeatureTutorialType["Arrangement"] = 4] = "Arrangement";
    FeatureTutorialType[FeatureTutorialType["Shop"] = 5] = "Shop";
    FeatureTutorialType[FeatureTutorialType["Gacha"] = 8] = "Gacha";
    FeatureTutorialType[FeatureTutorialType["JewelShop"] = 9] = "JewelShop";
})(FeatureTutorialType = exports.FeatureTutorialType || (exports.FeatureTutorialType = {}));
exports.HelperItemTypeNameMap = {
    オノ: 1,
    ピッケル: 2,
    スコップ: 3,
    銀のジョウロ: 4,
    いいエサ: 5,
    金のジョウロ: 4,
    お高級なエサ: 5,
    とりさんの水差し: 4,
    'お高級なエサ（1杯）': 5,
};
exports.COLON_MAX_NUMBER = 999999999;
exports.JEWEL_MAX_NUMBER = 999999999;
exports.TEXT_LIKE_MAXIMUM = '99999+';
exports.LIKE_MAXIMUM = 99999;
/** Pixel distance to be used as click/tap  **/
exports.CLICK_CLEARANCE_RANGE = 50;
/** Minimum number of kago at shop and warehouse menu */
exports.MIN_KAGO = 4;
/** Gacha cost type */
var GachaCostType;
(function (GachaCostType) {
    GachaCostType[GachaCostType["DailyFree"] = 1] = "DailyFree";
    GachaCostType[GachaCostType["Jewel"] = 2] = "Jewel";
    GachaCostType[GachaCostType["NormalTicket"] = 3] = "NormalTicket";
    GachaCostType[GachaCostType["DeluxTicket"] = 4] = "DeluxTicket";
})(GachaCostType = exports.GachaCostType || (exports.GachaCostType = {}));
/** Gacha ID */
var GachaID;
(function (GachaID) {
    GachaID[GachaID["Muffin"] = 1] = "Muffin";
    GachaID[GachaID["Daily"] = 2] = "Daily";
    GachaID[GachaID["Christmas"] = 3] = "Christmas";
    GachaID[GachaID["Halloween"] = 5] = "Halloween";
})(GachaID = exports.GachaID || (exports.GachaID = {}));
/** Campaign ID */
var CampaignID;
(function (CampaignID) {
    CampaignID[CampaignID["Halloween"] = 3] = "Halloween";
    CampaignID[CampaignID["Christmas2022"] = 4] = "Christmas2022";
})(CampaignID = exports.CampaignID || (exports.CampaignID = {}));
/**
 * Time of animation farm
 */
var TimeDurationAnimationMove;
(function (TimeDurationAnimationMove) {
    TimeDurationAnimationMove[TimeDurationAnimationMove["HoldDownArrow"] = 500] = "HoldDownArrow";
    TimeDurationAnimationMove[TimeDurationAnimationMove["ShowButtonIcon"] = 300] = "ShowButtonIcon";
})(TimeDurationAnimationMove = exports.TimeDurationAnimationMove || (exports.TimeDurationAnimationMove = {}));
