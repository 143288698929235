<template>
  <div ref="container">
    <div
      ref="title"
      class="title"
      :style="{ backgroundImage: guideImageUrl }"
    ></div>
    <div class="content">
      <div
        class="list-item"
        :id="`category_${index}`"
        v-for="(category, index) in categoryList"
        :key="category.category"
      >
        <div class="list-title">
          <span
            ref="arrow"
            class="arrow"
            :style="{ backgroundImage: guideImageUrl }"
          ></span>
          {{ category.category }}
        </div>
        <div
          class="button item"
          v-for="item in category.contents"
          :key="item.title"
        >
          <div
            class="item-content"
            v-if="!item.hide"
            @click="
              showGuideDialog({
                ...item,
                imageFilePath: category.imageFilePath,
              })
            "
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <GuideDialog
      v-if="showGuideDetail"
      :guideData="currentGuideData"
      @closeDialog="showGuideDetail = false"
    ></GuideDialog>
  </div>
</template>

<script>
import GuideDialog from './GuideDialog.vue';

export default {
  components: {
    GuideDialog,
  },
  props: {
    categoryList: { required: true },
    guideImageUrl: { required: true },
  },
  data() {
    return {
      showGuideDetail: false,
      currentGuideData: null,
    };
  },
  methods: {
    showGuideDialog(item) {
      this.currentGuideData = item;
      this.showGuideDetail = true;
    },
    handleResponsive() {
      // Calculate the size of title to make it responsive
      const multiplier = 0.00134;
      const containerWidth = this.$refs.container.clientWidth;
      this.$refs.title.style.backgroundPosition = `0 ${
        -258 * containerWidth * multiplier
      }px`;
      this.$refs.title.style.height = `${84 * containerWidth * multiplier}px`;

      // Make arrow responsive
      this.$refs.arrow.forEach((item) => {
        item.style.transform = `scale(${containerWidth * 0.0012})`;
      });
    },
  },
  mounted() {
    this.handleResponsive();
    window.addEventListener('resize', this.handleResponsive);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResponsive);
  },
};
</script>

<style scoped>
.title {
  background-size: 100%;
}

.arrow {
  display: inline-block;
  width: 40px;
  height: 26px;
  background-position: -152px -492px;
  transform-origin: right bottom;
  -ms-transform-origin: right bottom; /* IE 9 */
}

.content {
  color: #865c35;
  padding: 4%;
}

.list-item {
  font-size: 1.2em;
  font-weight: 600;
  color: #a1672a;
}

.list-item:not(:first-child) {
  margin-top: 4%;
}

.list-title {
  text-align: center;
  margin-bottom: 4%;
}

.item {
  font-size: 0.9em;
  width: 96%;
  margin: auto;
}

.list-item .item:not(:first-child) {
  margin-top: 4%;
}

.item-content {
  color: #95693f;
  font-size: 1em;
  border-radius: 0.7em;
  background-color: #faf8ee;
  padding: 2% 0;
  text-align: center;
  border: 0.2em solid #dfcfa5;
  box-shadow: 0 0.2em #dfcfa5;
}
</style>