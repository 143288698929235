<template>
  <div ref="container">
    <div
      ref="title"
      class="title"
      :style="{ backgroundImage: guideImageUrl }"
    ></div>
    <div class="list-item">
      <div
        class="button item"
        v-for="(item, index) in categoryList"
        :key="item.category"
        @click="jumpToDestination(index)"
      >
        <div class="item-content">{{ item.category }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryList: { required: true },
    guideImageUrl: { required: true },
  },
  methods: {
    jumpToDestination(index) {
      document
        .getElementById(`category_${index}`)
        .scrollIntoView({ behavior: 'smooth' });
    },
    handleResponsive() {
      // Calculate the size of title to make it responsive
      const multiplier = 0.00134;
      const containerWidth = this.$refs.container.clientWidth;
      this.$refs.title.style.backgroundPosition = `0 ${
        -174 * containerWidth * multiplier
      }px`;
      this.$refs.title.style.height = `${84 * containerWidth * multiplier}px`;
    },
  },
  mounted() {
    this.handleResponsive();
    window.addEventListener('resize', this.handleResponsive);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResponsive);
  },
};
</script>

<style scoped>
.title {
  background-size: 100%;
}

.list-item {
  font-size: 0.9em;
  font-weight: 600;
  padding: 6% 3%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3%;
  row-gap: 1em;
}

.item-content {
  color: #95693f;
  font-size: 1.15em;
  border-radius: 0.7em;
  background-color: #faf8ee;
  padding: 4% 0;
  text-align: center;
  border: 0.2em solid #dfcfa5;
  box-shadow: 0 0.2em #dfcfa5;
}
</style>