"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SceneKeys = void 0;
var SceneKeys;
(function (SceneKeys) {
    SceneKeys["Boot"] = "boot";
    SceneKeys["Preloader"] = "preloader";
    SceneKeys["Game"] = "game";
    SceneKeys["Layout"] = "layout";
    SceneKeys["Farm"] = "farm";
    SceneKeys["Shop"] = "shop";
    SceneKeys["ShopHeader"] = "shop_header";
    SceneKeys["WareHouseHeader"] = "warehouse_header";
    SceneKeys["WareHouse"] = "warehouse";
    SceneKeys["DataState"] = "data_state";
    SceneKeys["Message"] = "message";
    SceneKeys["Notification"] = "notification";
    SceneKeys["Mission"] = "mission";
    SceneKeys["Post"] = "post";
    SceneKeys["Kitchen"] = "kitchen";
    SceneKeys["CraftShop"] = "craftshop";
    SceneKeys["UserProfile"] = "user_profile";
    SceneKeys["CurrencyShop"] = "shop_currency";
    SceneKeys["Menu"] = "menu";
    SceneKeys["Gacha"] = "gacha";
    SceneKeys["Tutorial"] = "tutorial";
    SceneKeys["Story"] = "story";
    SceneKeys["Guide"] = "guide";
    SceneKeys["Help"] = "help";
    SceneKeys["LevelUp"] = "level_up";
    SceneKeys["Animation"] = "animation";
    SceneKeys["LoadingSpinner"] = "loading_spinner";
    SceneKeys["LoginBonus"] = "login_bonus";
    SceneKeys["FeatureTutorial"] = "feature_tutorial";
    SceneKeys["Album"] = "album";
    SceneKeys["VisitUser"] = "visit_user";
    SceneKeys["SpeedUpItems"] = "speed_up_items";
    SceneKeys["DeliveryStepUpModal"] = "delivery_step_up_modal";
    SceneKeys["EventIntroduceModal"] = "event_introduce_modal";
    SceneKeys["Modal"] = "modal";
    SceneKeys["WeeklyRankingIntroduceModal"] = "weekly_ranking_introduce_modal";
})(SceneKeys = exports.SceneKeys || (exports.SceneKeys = {}));
