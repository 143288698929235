<template>
  <div id="guide-menu" :style="{ backgroundImage: tileImageUrl }">
    <GuideHeader :guideImageUrl="guideImageUrl"></GuideHeader>
    <div class="guide-content">
      <div class="intro" :style="{ backgroundImage: guideImageUrl }"></div>

      <!-- Search by category -->
      <SearchByCategory
        :guideImageUrl="guideImageUrl"
        :categoryList="guideMenuData.how_to_play"
      ></SearchByCategory>

      <!-- Search by list -->
      <SearchByList
        :guideImageUrl="guideImageUrl"
        :categoryList="guideMenuData.how_to_play"
      ></SearchByList>
    </div>
  </div>
</template>

<script>
import guideMenuData from '../../../assets/guide/guide_data.json';
import GuideHeader from './GuideHeader.vue';
import SearchByCategory from './SearchByCategory.vue';
import SearchByList from './SearchByList.vue';

export default {
  components: {
    GuideHeader,
    SearchByCategory,
    SearchByList,
  },
  data() {
    return {
      tileImageUrl: 'url(images/game/common/repeat/bg_repeatGuideHelp.png)',
      guideImageUrl: 'url(images/guide/img_guide.png)',
      guideMenuData: guideMenuData,
    };
  },
};
</script>

<style scoped>
#guide-menu {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  background-repeat: repeat-y;
  background-size: contain;
  z-index: 1;
}

img {
  width: 100%;
  display: block;
}

.guide-content {
  height: 100%;
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.guide-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.guide-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.intro {
  height: 17.3%;
  background-size: 100%;
}
</style>