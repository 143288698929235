<template>
  <div v-if="listData.length > 0" class="noti-list">
    <NotiItem
      v-for="item in listData"
      :key="item.id"
      :itemData="item"
    ></NotiItem>
  </div>
  <div class="empty-list flex-center" v-else>お知らせはまだないよ</div>
</template>
<script>
import NotiItem from './NotiItem.vue';

export default {
  components: {
    NotiItem,
  },
  props: {
    listData: {
      required: true,
    },
  },
};
</script>

<style scoped>
.noti-list {
  height: 100%;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noti-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noti-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.empty-list {
  color: #8c623a;
  font-weight: 600;
  font-size: 0.9em;
  height: 50%;
  background-color: #e8dfc7;
  margin: 3%;
  border-radius: 0.8em;
}
</style>