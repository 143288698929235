<template>
  <div class="feedback-box">
    <span class="feedback-leading">
      はぐくまファームへのご意見・ご要望はご意見 BOXにて承っております
    </span>
    <a
      href="javascript:void(0)"
      @click="handleFeedbackButtonClick"
      class="feedback-button"
    >
      ご意見BOX
    </a>
  </div>
</template>

<script>
import { handleFeedbackButtonClick } from '../../../utils/Utils';

export default {
  methods: {
    handleFeedbackButtonClick,
  }
}
</script>


<style scoped>
  .feedback-box {
    margin-top: 9.067%;
    padding: 5.73% 15.152% 9.73%;
    text-align: center;
    border-radius: 1.5em;
    background-color: #eddcab;
  }

  .feedback-box .feedback-leading {
    font-size: 0.76em;
    font-weight: 600;
    color: #96693b;
    font-family: roundedMplus;
  }

  .feedback-box .feedback-button {
    display: block;
    margin-top: 7.24%;
    padding: 1.934% 0;
    font-size: 1.3em;
    color: #e4faf4;
    background-color: #58bba7;
    text-decoration: none;
    box-shadow: 0 0.3em #45a38e;
    border-radius: 0.5em;
  }
</style>